/**
 * Based on:
 *
 * https://github.com/vuejs/vue/issues/214#issuecomment-692064376
 *
 * Adds a $recompute method in Vue objects to explicitly tell Vue to re-compute a computed.
 * This is needed as Vue computed are cached and there are situations where the observable doesn't trigger the needed changes.
 *
 * @param {String} computedName the name of the computed to re-compute
 * @param {Boolean} forceUpdate tells if the component needs to to a $forceUpdate too, to be re-rendered
 */
import Vue from "vue";

Vue.prototype.$recompute = function(
  computedName,
  forceUpdate /* default: true */
) {
  if (this._computedWatchers[computedName]) {
    this._computedWatchers[computedName].run();
    if (forceUpdate || typeof forceUpdate == "undefined") {
      this.$forceUpdate();
    }
  }
};
