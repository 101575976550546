import { ApiHandler } from "@/lib/ApiHandler";

class InfoCardPageService extends ApiHandler {
  constructor () {
    super("/api/info-card-pages");
  }

  async list () {
    return await this.get("")
      .then(res => res.json())
      .then(res => res.result);
  }

  async read (id) {
    return await this.get(id)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async cardLayouts () {
    return await this.get("cardLayouts")
      .then(res => res.json())
      .then(res => res.result);
  }

  async create (body) {
    return await this.post("", body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async update (id, body) {
    return await this.put(id, body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async setEnabled (idInfoCardPage, status) {
    return await this.patch(`${idInfoCardPage}/${status}`)
      .then(res => res.json())
      .then(res => res);
  }

  async listCards (idInfoCardPage) {
    return await this.get(`${idInfoCardPage}/cards`)
      .then(res => res.json())
      .then(res => res.result);
  }

  async readCard (idInfoCardPage, idCard) {
    return await this.get(`${idInfoCardPage}/cards/${idCard}`)
      .then(res => res.json())
      .then(res => res.result);
  }

  async cloneCard (idInfoCardPage, idInfoCard, body) {
    return await this.post(`${idInfoCardPage}/cards/${idInfoCard}/clone`, body)
    .then(res => res.json())
    .then(res => res.result);
  }

  async createCard (idInfoCardPage, body) {
    return await this.post(`${idInfoCardPage}/cards`, body)
      .then(res => res.json())
      .then(res => res.result);
  }

  async updateCard (idInfoCardPage, idCard, body) {
    return await this.put(`${idInfoCardPage}/cards/${idCard}`, body)
      .then(res => res.json())
      .then(res => res.result);
  }

  async deleteCard (idInfoCardPage, idCard) {
    return await this.delete(`${idInfoCardPage}/cards/${idCard}`)
      .then(res => res.json())
      .then(res => res.result);
  }

  async saveCardsOrder (idInfoCardPage, ordereredInfoCards) {
    return await this.put(`${idInfoCardPage}/updateOrder`, ordereredInfoCards)
      .then(res => res.json())
      .then(res => res);
  }

  async setCardEnabled (idInfoCardPage, idInfoCard, status) {
    return await this.patch(`${idInfoCardPage}/cards/${idInfoCard}/${status}`)
      .then(res => res.json())
      .then(res => res);
  }
}

export const infoCardPageService = new InfoCardPageService();
