export const state = {
  sectionTitle: null,
  lastFiltersByRoute: {}
};

export const mutations = {
  SET_SECTION_TITLE(state, title) {
    state.sectionTitle = title;
  },
  SET_ROUTE_FILTER(state, {route, filterValue}) {
    state.lastFiltersByRoute[route] = filterValue;
  },
  RESET_ROUTE_FILTERS(state){
    state.lastFiltersByRoute= {};
  }
};
