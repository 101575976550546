<template>
  <v-app>
    <div id="app">
      <div v-if="$route.meta.layout != 'external' && currentUser != null">
        <Container :fullWidth="true">
          <div class="wrapper-nav-top hide-lg">
            <NavTop />
          </div>

          <div class="wrapper-main">
            <div class="wrapper-nav">
              <Navbar
                :navItemList="navItemList"
                :firstName="currentUser.firstName"
                :lastName="currentUser.lastName"
              />
            </div>
            <div class="wrapper-content">
              <div class="wrapper-nav-top hide-all show-lg">
                <NavTop />
              </div>

              <div class="main-content">
                <router-view />
              </div>
            </div>
            <Toast v-if="toast.visible" :text="toast.text" :type="toast.type" />
          </div>
        </Container>
      </div>
      <div v-if="$route.meta.layout == 'external'">
        <Container :fullWidth="true">
          <div class="main-content full-page">
            <router-view />

            <Toast v-if="toast.visible" :text="toast.text" :type="toast.type" />
          </div>
        </Container>
      </div>
    </div>
  </v-app>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import Container from "@/components/dom/Container.vue";
import Navbar from "@/components/navbar/Navbar.vue";
import NavTop from "@/components/navtop/NavTop.vue";
import SvgIcon from "@/components/dom/SvgIcon.vue";
import { EventBus } from "@/lib/EventBus";
import Toast from "@/components/dom/Toast.vue";

@Component({
  components: {
    Toast,
    Navbar,
    NavTop,
    Container,
    SvgIcon,
  },
  inject: {
    languageService: "languageService",
    groupService: "groupService",
    uiService: "uiService",
    authService: "authService",
  },
})
export default class App extends Vue {
  appMenu = [];
  toastModel = {
    visible: false,
    text: "",
    type: "",
  };

  menuMap = {
    User: {
      icon: "mdi-account-group",
      title: this.translations.labels.users
    },
    Event: {
      icon: "mdi-calendar-star",
      title: this.translations.labels.events
    },
    Media: {
      icon: "mdi-folder-multiple-image",
      title: this.translations.labels.mediaLibrary
    },
    Hall: {
      icon: "mdi-filter-variant",
      title: this.translations.labels.halls
    },
    InfoDesk: {
      icon: "mdi-information",
      title: this.translations.labels.infoPoint
    },
    Auditorium: {
      icon: "mdi-theater",
      title: this.translations.labels.auditoriums
    },
    Area: {
      icon: "mdi-arrange-send-backward",
      title: this.translations.labels.area
    },
    Pavilion: {
      icon: "mdi-office-building-outline",
      title: this.translations.labels.pavilions
    },
    Stand: {
      icon: "mdi-storefront-outline",
      title: this.translations.labels.stands
    },
    OnDemand: {
      icon: "mdi-star-settings-outline",
      title: this.translations.labels.ondemands
    },
    VisitorLevel: {
      icon: "mdi-account-details-outline",
      title: this.translations.labels.visitorLevel
    },
    Menu: {
      icon: "mdi-dots-horizontal",
      title: this.translations.labels.menu
    },
    Category: {
      icon: "mdi-group",
      title: this.translations.labels.categories
    },
    Taxonomy: {
      icon: "mdi-tag-multiple-outline",
      title: this.translations.labels.taxonomies
    },
    Privacy: {
      icon: "mdi-eye-check",
      title: this.translations.labels.privacies
    },
    Gallery: {
      icon: "mdi-tooltip-image-outline",
      title: this.translations.labels.galleries
    },
    InfoCardPage: {
      icon: "mdi-book-information-variant",
      title: this.translations.labels.infoCardPages
    },
    Configuration: {
      icon: "mdi-cog-outline",
      title: this.translations.labels.configuration
    },
    Language: {
      icon: "",
      title: this.translations.labels.configuration_language
    },
    Theme: {
      icon: "",
      title: this.translations.labels.configuration_theme
    },
    PlatformConfiguration: {
      icon: "",
      title: this.translations.labels.configuration_platformConfiguration
    },
    GTIConfiguration: {
      icon: "",
      title: this.translations.labels.configuration_gtiConfiguration
    },
    Translation: {
      icon: "",
      title: this.translations.labels.configuration_translations
    },
    Email: {
      icon: "",
      title: this.translations.labels.configuration_emails
    },
    CustomField: {
      icon: "",
      title: this.translations.labels.configuration_customFields
    },
    Security: {
      icon: "",
      title: this.translations.labels.configuration_security
    },
    CookiePrivacyPolicy: {
      icon: "",
      title: this.translations.labels.configuration_cookiePrivacyPolicy
    },
  }

  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }
  get currentUser() {
    return this.$store.state.authStore.currentUser;
  }
  get toast() {
    return this.toastModel;
  }

  setToast(visible, text, type) {
    this.toast.visible = visible;
    this.toast.text = text;
    this.toast.type = type;
  }

  toastHandler(visible, toastBody, type) {
    this.setToast(visible, toastBody.text, type);
    setTimeout(() => {
      this.setToast(false, "", "");
    }, toastBody.duration);
  }

  buildMenuItem(voice) {
    const ref = (typeof this.menuMap[voice.entityType] === "undefined")
      ? {}
      : this.menuMap[voice.entityType];
    return {
      icon: ref?.icon ?? "thunder",
      title: ref?.title ?? voice.entityType,
      link: voice.link,
      childItemList: (voice?.childItemList ?? []).map(child => this.buildMenuItem(child))
    };
  }

  get navItemList() {
    return this.appMenu.map(m => this.buildMenuItem(m));
  }

  async reloadMenu() {
    this.appMenu = await this.uiService.menu({cacheBust: true });
  }

  listener() {
    EventBus.$on("success", (toastBody) => {
      this.toastHandler(true, toastBody, "success");
    });

    EventBus.$on("error", (toastBody) => {
      this.toastHandler(true, toastBody, "error");
    });

    EventBus.$on("reload-menu", this.reloadMenu);
  }
  async mounted() {
      this.$store.commit("SET_CURRENT_LANG");
      this.listener();
      if (await this.authService.checkLoggedUser()) {
        this.reloadMenu();
        const groups = await this.groupService.list();
        this.$store.commit("SET_GROUPS", groups);
        const langs = await this.languageService.list();
        this.$store.commit("SET_LANGUAGES", langs);
      }
    }
}
</script>

<style lang="scss">
@import "@/scss/reset.scss";
@import "@/scss/utility.scss";
@import "@/scss/form-fields.scss";

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100vh;
  overflow: hidden;

  .main-content {
    position: relative;
    width: 100%;
    height: calc(100vh - 90px);
    padding-top: 10px;
    overflow: auto;

    &.full-page {
      height: 100vh;
      padding-top: 0;
    }
  }

  .wrapper-main {
    @media (min-width: $lg) {
      display: flex;
    }

    .wrapper-nav {
      width: 100vw;

      @media (min-width: $lg) {
        width: 20vw;
      }
    }

    .wrapper-content {
      position: relative;
      width: 100vw;

      @media (min-width: $lg) {
        width: 80vw;
      }
    }
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: $blue-dark;
    }
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 16px !important;
  }

  .text-start {
    font-size: 16px !important;
  }

  .v-data-footer {
    font-size: 16px !important;
  }

  .v-select__selection.v-select__selection--comma {
    font-size: 16px !important;
  }

  ul {
    li {
      font-size: 1.7rem;
    }
  }

  .not-valid {
    border: 2px solid red !important;
  }

  .myClass {
    color: #000 !important;
    caret-color: #000 !important;
    border-radius: 18px;

    label {
      color: #000 !important;
      font-size: 1.6rem !important;
      font-weight: 500;
      text-transform: capitalize;
    }
  }
}
</style>
