<template>
  <h3
    class="title-base"
    :class="{
      main: type === 1 || undefined,
      secondary: type === 2,
      thirdary: type === 3,
      center: center === 'center' || undefined,
      left: center === 'left',
      right: center === 'right',
      bold: bold
    }"
    :style="size ? `font-size:${size}rem` : null"
  >
    <slot></slot>
  </h3>
</template>

<script>
/*
  type:
   1 => primary
   2 => secondary
   3 => thirdary

   center:
    center => center
   left => left
   right => right

   size:
   pass as parameter the number of rem u want to override
*/

export default {
  name: "Title-base",
  props: {
    type: Number,
    bold: Boolean,
    center: String,
    size: Number
  }
};
</script>

<style lang="scss" scoped>
.title-base {
  color: $black;
  font-size: 2.2rem;
  font-weight: 600;

  &.center {
    text-align: center;
  }
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }

  &.main {
    background-color: $transparent !important;
    color: $main-color;
  }

  &.secondary {
    background-color: $transparent !important;
    color: $second-color;
  }

  &.thirdary {
    background-color: $transparent !important;
    color: $third-color;
  }

  &.bold {
    background-color: $transparent !important;
    font-weight: 800;
  }
}
</style>
