<template>
  <div class="navtop">
    <div class="group-left">
      <TitleBase>{{ sectionTitle }}</TitleBase>
    </div>

    <div class="group_right">
      <UserInfo />
    </div>
  </div>
</template>

<script>
import TitleBase from "@/components/dom/TitleBase.vue";
import UserInfo from "@/components/navtop/UserInfo.vue";

export default {
  components: {
    TitleBase,
    UserInfo
  },
  data() {
    return {
      showbackButton: window.history.length > 1,
      userInfo: {
        show: true
      }
    };
  },
  computed: {
    sectionTitle() {
      return this.$store.state.navigationStore.sectionTitle;
    }
  }
};
</script>

<style lang="scss" scoped>
.navtop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  box-shadow: 0 0 10px -2px $grey-light;
  width: 100%;
  height: 90px;
  padding: 0 25px;
  overflow-x: hidden;

  .group-left {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    min-width: 300px;
  }

  .group_right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 200px;
  }
}
</style>
