import { ApiHandler } from "@/lib/ApiHandler";

class StandService extends ApiHandler {
  constructor() {
    super("/api/stands");
  }

  async list(idPavilion) {
    return await this.get(idPavilion ? "?idPavilion=" + idPavilion : "")
      .then(res => res.json())
      .then(res => res.result);
  }

  async read(id) {
    return await this.get(id)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async create(body) {
    return await this.post("", body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async update(id, body) {
    return await this.put(id, body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async updateOrder(body) {
    return await this.put("updateOrder", body)
      .then(res => res.json())
      .then(res => res.result);
  }

  async duplicate(body, idStand) {
    return await this.post(`${idStand}/duplicate`, body)
      .then(res => res.json())
      .then(res => res.result);
  }

  async clone(body, idStand) {
    return await this.post(`${idStand}/clone`, body)
      .then(res => res.json())
      .then(res => res.result);
  }

  async setEnabled(idEvent, status) {
    return await this.patch(`${idEvent}/${status}`)
      .then(res => res.json())
      .then(res => res);
  }

  async setPublished(idEvent, status) {
    return await this.patch(`${idEvent}/${status}`)
      .then(res => res.json())
      .then(res => res);
  }

  async owners(idStand) {
    return await this.get(`${idStand}/owners`)
      .then(res => res.json())
      .then((res) => res.result);
  }

  async assignOwner(body, idStand) {
    return await this.post(`${idStand}/assign-owner`, body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async removeOwner(idStand, idUser) {
    return await this.delete(`${idStand}/remove-owner/${idUser}`)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }
}

export const standService = new StandService();
