export const state = {
  filters: {
    userActiveFilters: []
  }
};

export const mutations = {
  ADD_TO_FILTER(state, { name, value }) {
    state.filters[name].push(value);
    state.filters[name] = state.filters[name].sort((a, b) => {
      // order by group
      const aGroup = a.group.toLowerCase();
      const bGroup = b.group.toLowerCase();
      if (aGroup < bGroup) {
        return -1;
      }
      if (aGroup > bGroup) {
        return 1;
      }

      // then by name
      const aValue = a.value.toLowerCase();
      const bValue = b.value.toLowerCase();
      if (aValue > bValue) {
        return -1;
      }
      if (aValue < bValue) {
        return 1;
      }
      return 0;
    });
  },
  REMOVE_FROM_FILTER(state, { name, value }) {
    const index = state.filters[name].indexOf(value);
    if (index !== -1) {
      state.filters[name].splice(state.filters[name].indexOf(value), 1);
    }
  },
};
