import { ApiHandler } from "@/lib/ApiHandler";

class CustomFieldService extends ApiHandler {
  constructor () {
    super("/api/custom-fields");
  }

  async fieldTypes() {
    return await this.get("field-types")
      .then(res => res.json())
      .then(res => res.result);
  }

  async list() {
    return await this.get("")
      .then(res => res.json())
      .then(res => res.result);
  }

  async read(id) {
    return await this.get(id)
      .then(res => res.json())
      .then((res) => res.result);
  }

  async create(body) {
    return await this.post("", body)
      .then(res => res.json())
      .then(res => res.result);
  }

  async update(id, body) {
    return await this.put(id, body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async setEnabling (id, status) {
    return await this.patch(`${id}/${status}`)
      .then(res => res.json())
      .then(res => res);
  }

  async saveOrder(newOrder) {
    return await this.put("updateOrder", newOrder)
      .then(res => res.json())
      .then(res => res);
  }
}

export const customFieldService = new CustomFieldService();