import { ApiHandler } from "@/lib/ApiHandler";
class AuditoriumService extends ApiHandler{
  constructor(){
    super("/api/auditoriums");
  }

  async list () {
    return await this.get("")
      .then(res => res.json())
      .then(res => res.result);
  }

  async read (id) {
    return await this.get(id)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async create (body) {
    return await this.post("",body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async update (id, body) {
    return await this.put(`${id}`, body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async setEnabled (id, status) {
    return await this.patch(`${id}/${status}`)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }
}

export const auditoriumService = new AuditoriumService();