import { ApiHandler } from "@/lib/ApiHandler";

class VisitorPermissionService extends ApiHandler {
  constructor () {
    super("/api/visitorPermissions");
  }

  async attendees(entityType, idEntity) {
    return await this.get(`attendees/${entityType}/${idEntity}`)
      .then(res => res.json())
      .then(res => res.result);
  }

  async attendances(idUser) {
    return await this.get(`attendances/${idUser}`)
      .then(res => res.json())
      .then(res => res.result);
  }

  async exportAttendees(entityType, idEntity) {
    return await this.get(`export/attendees/${entityType}/${idEntity}`)
      .then(res => res.json())
      .then(res => res.result);
  }

  async revoke(id) {
    return await this.delete(`${id}`)
      .then(res => res.json())
      .then(res => res);
  }

  async permit(code, idUser) {
    return await this.post("", { code: code, idUser: idUser })
      .then(res => res.json())
      .then(res => res);
  }
}

export const visitorPermissionService = new VisitorPermissionService();