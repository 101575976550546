<template>
  <div class="swp-toast" :class="type == 'success' ? 'success' : 'error'">
    <v-icon v-if="type === 'success'" color="#fff"
      >mdi-check-circle-outline</v-icon
    >
    <v-icon v-else color="#fff">mdi-close-circle-outline</v-icon>
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    type: String
  }
};
</script>

<style lang="scss" scoped>
.swp-toast {
  position: absolute;
  bottom: 0;
  color: $white;
  font-size: 1.6rem;
  font-weight: 700;
  padding: 20px;
  z-index: 99;
  width: 100%;

  &.success {
    background-color: $success !important;
  }

  &.error {
    background-color: $fail !important;
  }
}
</style>
