import { ApiHandler } from "@/lib/ApiHandler";

class EmailService extends ApiHandler {
  constructor () {
    super("/api/emails");
  }

  async listLayouts () {
    return await this.get("layouts")
      .then(res => res.json())
      .then(res => res.result);
  }

  async readLayout (id) {
    return await this.get(`layouts/${id}`)
      .then(res => res.json())
      .then(res => res.result);
  }

  async createLayout (model) {
    return await this.post("layouts", model)
      .then(res => res.json())
      .then(res => res.result);
  }

  async updateLayout (id, model) {
    return await this.put(`layouts/${id}`, model)
      .then(res => res.json())
      .then(res => res.result);
  }

  async listTemplateCodes () {
    return await this.get("templates/codes")
      .then(res => res.json())
      .then(res => res.result);
  }

  async listTemplates () {
    return await this.get("templates")
      .then(res => res.json())
      .then(res => res.result);
  }

  async readTemplate (id) {
    return await this.get(`templates/${id}`)
      .then(res => res.json())
      .then(res => res.result);
  }

  async createTemplate (model) {
    return await this.post("templates", model)
      .then(res => res.json())
      .then(res => res.result);
  }

  async updateTemplate (id, model) {
    return await this.put(`templates/${id}`, model)
      .then(res => res.json())
      .then(res => res.result);
  }
}

export const emailService = new EmailService();
