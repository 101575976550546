import { ApiHandler } from "@/lib/ApiHandler";

class LinkService extends ApiHandler {
  constructor () {
    super("/api/links");
  }

  async list (fromId) {
    return await this.get("", { from: fromId })
      .then(res => res.json())
      .then(res => res.result);
  }

  async read (id) {
    return await this.get(id)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async create (body) {
    return await this.post("", body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async remove (id) {
    return await  this.delete(id)
    .then(res => res.json())
    .then(res => res.result);
  }

  async update (id, body) {
    return await this.put(id, body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async types (entityType) {
    entityType = entityType ?? "";
    const endpoint = entityType ? `types/${entityType}` : "types";
    return await this.get(endpoint)
      .then(res => res.json())
      .then(res => res.result);
  }

  async hotspotTypes () {
    return await this.get("hotspotTypes")
      .then(res => res.json())
      .then(res => res.result);
  }

  async hotspotPositions () {
    return await this.get("hotspotPositions")
      .then(res => res.json())
      .then(res => res.result);
  }

  async hotspotAnimations () {
    return await this.get("hotspotAnimations")
      .then(res => res.json())
      .then(res => res.result);
  }

  async hotspotFontSizes () {
    return await this.get("hotspotFontSizes")
      .then(res => res.json())
      .then(res => res.result);
  }

}

export const linkService = new LinkService();
