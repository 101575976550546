export const state = {
  list: null,
  currentIdLink: "",
  currentTypeLink: ""
};

export const mutations = {
  SAVE_TYPES_LINKS_LIST(state, list) {
    state.list = list;
  },
  SAVE_CURRENT_TO_LINK(state, item) {
    state.currentIdLink = "";
    state.currentIdLink = item;
  },
  SAVE_CURRENT_TO_LINK_TYPE(state, item) {
    state.currentTypeLink = "";
    state.currentTypeLink = item;
  }
};
