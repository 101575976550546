import Vue from "vue";
import App from "./App.vue";
import _env from "./plugins/env";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/dragdrop";
import "./plugins/recompute";
import "./plugins/validation";
import * as Services from "./services/index";

Vue.config.productionTip = false;

window._env = _env;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  provide () {
    return {
      // services
      ...Services
    };
  }
}).$mount("#app");
