import { ApiHandler } from "@/lib/ApiHandler";

class PlatformConfigurationService extends ApiHandler {
  constructor() {
    super("/api/platform-configurations");
  }

  async list(category) {
    return await this.get(category)
      .then(res => res.json())
      .then(res => res.result);
  }

  async getByName(name) {
    return await this.get("name/" + name)
      .then(res => res.json())
      .then(res => res.result);
  }

  async mainEventCode() {
    return await this.get("main-event-code")
      .then(res => res.json())
      .then(res => res.result);
  }

  async update(body) {
    return await this.put("", body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }
}

export const platformConfigurationService = new PlatformConfigurationService();
