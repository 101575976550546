import { ApiHandler } from "@/lib/ApiHandler";

class GroupService extends ApiHandler {
  constructor () {
    super("/api/groups");
  }

  async list () {
    return await this.get("")
      .then(res => res.json())
      .then(res => res.result);
  }

}

export const groupService = new GroupService();