import { ApiHandler } from "@/lib/ApiHandler";

class DefaultSwagbagFileService extends ApiHandler {
  constructor() {
    super("/api/defaultSwagbagFiles");
  }

  async list(idEvent) {
    return await this.get(idEvent ? "?idEvent=" + idEvent : "")
      .then(res => res.json())
      .then(res => res.result);
  }

  async listAssignedUsers(id){
    return await this.get(`${id}/getAssignedUsers`)
      .then(res => res.json())
      .then(res => res.result);
  }

  async read(id) {
    return await this.get(id)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async create(body) {
    return await this.post("", body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async update(id, body) {
    return await this.put(id, body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async remove(id) {
    return await this.delete(id)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }
}

export const defaultSwagbagFileService = new DefaultSwagbagFileService();
