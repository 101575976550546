export const state = {
  list: null,
  infoPoint: null
};

export const mutations = {
  SAVE_INFO_POINT_LIST(state, list) {
    state.list = list;
  },
  SAVE_INFO_POINT(state, infoPoint) {
    state.infoPoint = infoPoint;
  }
};
