import { ApiHandler } from "@/lib/ApiHandler";

class ThemeService extends ApiHandler {
  constructor() {
    super("/api/color-palettes");
  }

  async list() {
    return await this.get("")
      .then(res => res.json())
      .then(res => res.result);
  }

  async read(id) {
    return await this.get(id)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async create(body) {
    return await this.post("", body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async update(id, body) {
    return await this.put(id, body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }


  async select(id) {
    return await this.patch(`${id}/select`, {})
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }
}

export const themeService = new ThemeService();
