import { ApiHandler } from "@/lib/ApiHandler";

class PavilionService extends ApiHandler {
  constructor () {
    super("/api/pavilions");
  }

  async list () {
    return await this.get("")
      .then(res => res.json())
      .then(res => res.result);
  }

  async read (id) {
    return await this.get(id)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async create (body) {
    return await this.post("", body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async update (id, body) {
    return await this.put(id, body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async setEnabled (idEvent, status) {
    return await this.patch(`${idEvent}/${status}`)
      .then(res => res.json())
      .then(res => res);
  }
}

export const pavilionService = new PavilionService();
