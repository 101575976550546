export const state = {
  app_config: {
    groups: null,
    groupsList: [],
    languagesList: [],
    speaker: null
  }
};

export const mutations = {
  SET_GROUPS(state, payload) {
    state.app_config.groups = payload;
    payload.forEach(x => {
      state.app_config.groupsList.push({ id: x.id, name: x.name });

      if (x.name.toLowerCase() === "speaker") {
        state.app_config.speaker = x.id;
      }
    });
  },
  SET_CONFIG_LANGUAGES(state, payload) {
    state.app_config.languagesList = payload;
  }
};
