<template>
  <div class="nav-items">
    <div class="item" v-for="(item, index) in navItemList" :key="index">
      <div
        v-if="item.childItemList != undefined && item.childItemList.length > 0"
        class="action"
        :id="'idAction_' + index"
        @click="openAccordion(index)"
      >
        <v-icon v-if="item.icon" dark class="menu-icon">{{ item.icon }}</v-icon>
        <p class="title">{{ item.title }}</p>
        <v-icon class="icon arrow">mdi-chevron-right</v-icon>
      </div>
      <div v-else>
        <router-link :to="item.link" @click.native="resetFilters">
          <div class="action">
            <v-icon v-if="item.icon" dark class="menu-icon">{{ item.icon }}</v-icon>
            <p class="title">{{ item.title }}</p>
          </div>
        </router-link>
      </div>

      <div class="accordion hidden" :id="'id_' + index">
        <div
          class="sub-item"
          v-for="subItem in item.childItemList"
          :key="subItem.title"
        >
          <router-link :to="subItem.link" class="link">
            {{ subItem.title }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    navItemList: Array
  },
  computed: {
    translations() {
      return this.$store.state.translationsStore.currentLang;
    }
  },
  methods: {
    openAccordion(_index) {
      const el = document.getElementsByClassName("item");
      const elAccordion = document.getElementById("id_" + _index);

      if (elAccordion.classList.contains("hidden")) {
        elAccordion.classList.remove("hidden");
        el[_index].classList.add("active");
      } else {
        elAccordion.classList.add("hidden");
        el[_index].classList.remove("active");
      }
    },
    resetFilters(){
      this.$store.commit("RESET_ROUTE_FILTERS");
    }
  }
};
</script>

<style lang="scss" scoped>
.nav-items {
  padding: 50px 0 50px 40px;

  .item {
    color: $white_07;

    &:last-child {
      .action {
        border-bottom: 0;
      }
    }

    &.active {
      color: $white;

      .action {
        opacity: 1;

        .arrow {
          transform: rotate(90deg);
        }
      }
    }

    .action {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid $white;
      text-align: left;
      padding: 15px 30px 15px 0;
      transition: 0.3s;
      opacity: 0.5;
      cursor: pointer;

      &:hover {
        opacity: 1;

        .title {
          color: $white !important;
        }
      }

      .svg-icon {
        margin-right: 10px;
        svg path {
          fill: $white;
        }
      }

      .menu-icon {
        margin-right: 10px;
      }

      .title {
        color: $white;
        font-size: 1.8rem !important;
        font-weight: 400;
        line-height: 35px;
        margin-bottom: 0;
      }

      .arrow {
        position: absolute;
        color: $white;
        right: 15px;
        margin: 0;
        transition: all 0.3s ease-in-out;
      }
    }

    .accordion {
      background-color: $main-color;
      max-height: none;
      transition: all 0.3s ease-in-out;
      overflow: hidden;

      &.hidden {
        max-height: 0;
      }

      .sub-item {
        border-bottom: 1px solid $main-color-dark;
        text-align: left;
        padding: 0 30px;
        transition: all 0.3s ease-in-out;

        &:last-child {
          border-bottom: 0;
        }

        .link {
          display: block;
          color: $white;
          text-decoration: none;
          font-size: 1.4rem;
          padding: 10px 0;
          transition: all 0.3s ease-in-out;
        }

        &:hover {
          background-color: $main-color-dark;
        }
      }
    }
  }
}
</style>
