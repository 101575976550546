import { ApiHandler } from "@/lib/ApiHandler";

class LoungeService extends ApiHandler {
  constructor () {
    super("/api/lounges");
  }

  async list () {
    return await this.get("")
      .then(res => res.json())
      .then(res => res.result);
  }

  async read (id) {
    return await this.get(id)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

}

export const loungeService = new LoungeService();
