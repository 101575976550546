import Vue from "vue";
import VueRouter from "vue-router";
import auth from "../middleware/auth";
import _env from "@/plugins/env";

Vue.use(VueRouter);

const routes = [
  //!External layout pages
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/auth/login/Login.vue"),
    meta: {
      layout: "external"
    }
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("../views/auth/forgot-password/ForgotPassword.vue"),
    meta: {
      layout: "external"
    }
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("../views/auth/reset-password/ResetPassword.vue"),
    meta: {
      layout: "external"
    }
  },

  {
    path: "/",
    name: "Home",
    component: () => import("../views/home/Home.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //! Configurations pages
  {
    path: "/configurations/platform-languages",
    name: "PlatformLanguages",
    component: () =>
      import(
        "../views/menu/configurations/platform-languages/platformLanguages.vue"
      ),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/configurations/color-palette/list",
    name: "PlatformColorPalette_list",
    component: () =>
      import(
        "../views/menu/configurations/platform-theme/list/PlatformColorPalette_list.vue"
      ),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/configurations/color-palette/new",
    name: "PlatformColorPalette_new",
    component: () =>
      import(
        "../views/menu/configurations/platform-theme/new/PlatformColorPalette_new.vue"
      ),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/configurations/color-palette/edit/:id",
    name: "PlatformColorPalette_edit",
    component: () =>
      import(
        "../views/menu/configurations/platform-theme/edit/PlatformColorPalette_edit.vue"
      ),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },

  //#region USERS
  {
    path: "/user/list",
    name: "User_list",
    component: () => import("../views/menu/user/list/user_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/user/detail/:id",
    name: "User_detail",
    component: () => import("../views/menu/user/detail/user_detail.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/user/edit/:id",
    name: "User_edit",
    component: () => import("../views/menu/user/edit/user_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/user/new",
    name: "User_new",
    component: () => import("../views/menu/user/new/user_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/user/logs/:id",
    name: "User_Logs",
    component: () => import("../views/menu/user/logs/logs.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/user/import",
    name: "User_Import",
    component: () => import("../views/menu/user/import/user_import"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //#endregion

  //#region HALLS
  {
    path: "/hall/list",
    name: "Hall_list",
    component: () => import("../views/menu/hall/list/hall_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/hall/detail/:id",
    name: "Hall_detail",
    component: () => import("../views/menu/hall/detail/hall_detail.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/hall/edit/:id",
    name: "Hall_edit",
    component: () => import("../views/menu/hall/edit/hall_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/hall/new",
    name: "Hall_new",
    component: () => import("../views/menu/hall/new/hall_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/hall/clone/:id",
    name: "Hall_clone",
    component: () => import("../views/menu/hall/clone/hall_clone.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //#endregion

  //#region PAVILIONS
  {
    path: "/pavilion/list",
    name: "Pavilion_list",
    component: () => import("../views/menu/pavilion/list/pavilion_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/pavilion/detail/:id",
    name: "Pavilion_detail",
    component: () => import("../views/menu/pavilion/detail/pavilion_detail.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/pavilion/edit/:id",
    name: "Pavilion_edit",
    component: () => import("../views/menu/pavilion/edit/pavilion_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/pavilion/new",
    name: "Pavilion_new",
    component: () => import("../views/menu/pavilion/new/pavilion_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/pavilion/stand-order/:id",
    name: "Pavilion_stand-order",
    component: () => import("../views/menu/pavilion/stand-order/pavilion_stand-order.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //#endregion

  //#region AREA
  {
    path: "/area/list",
    name: "Area_list",
    component: () => import("../views/menu/area/list/area_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/area/detail/:id",
    name: "Area_detail",
    component: () => import("../views/menu/area/detail/area_detail.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/area/edit/:id",
    name: "Area_edit",
    component: () => import("../views/menu/area/edit/area_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/area/new",
    name: "Area_new",
    component: () => import("../views/menu/area/new/area_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //#endregion

  //#region LINKS

  {
    path: "/link/:id/:entityType",
    name: "Link List",
    component: () => import("../views/links/list/link_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/link/new/:idParent/:entityType",
    name: "Link New",
    component: () => import("../views/links/new/link_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/link/edit/:id/:idParent/:entityType",
    name: "Link Edit",
    component: () => import("../views/links/edit/link_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //#endregion

  //#region EVENTS
  {
    path: "/event/list",
    name: "Event_list",
    component: () => import("../views/menu/event/list/event_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/event/detail/:id",
    name: "Event_detail",
    component: () => import("../views/menu/event/detail/event_detail.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/event/edit/:id",
    name: "Event_edit",
    component: () => import("../views/menu/event/edit/event_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/event/new",
    name: "Event_new",
    component: () => import("../views/menu/event/new/event_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/event/:id/config",
    name: "Event_config",
    component: () => import("../views/menu/event/config/event_config.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //#endregion

  //#region DEFAULT SWAGBAG FILES
  {
    path: "/swagbag/list/:id",
    name: "SwagbagFilesList",
    component: () => import("../views/menu/event/defaultSwagbagFile/list/defaultSwagbagFile_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/swagbag/list/:idEvent/new",
    name: "SwagbagFiles New",
    component: () => import("../views/menu/event/defaultSwagbagFile/new/defaultSwagbagFile_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/swagbag/list/:idEvent/edit/:id",
    name: "SwagbagFiles Edit",
    component: () => import("../views/menu/event/defaultSwagbagFile/edit/defaultSwagbagFile_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //#endregion

  //#region AUDITORIUMS
  {
    path: "/auditorium/list",
    name: "Auditorium list",
    component: () =>
      import("../views/menu/auditorium/list/auditorium_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/auditorium/detail/:id",
    name: "Auditorium Detail",
    component: () =>
      import("../views/menu/auditorium/detail/auditorium_detail.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/auditorium/edit/:id",
    name: "Auditorium Edit",
    component: () =>
      import("../views/menu/auditorium/edit/auditorium_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/auditorium/new",
    name: "Auditorium New",
    component: () => import("../views/menu/auditorium/new/auditorium_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },

  //#endregion

  //#region INFOPOINT
  {
    path: "/infopoint/list",
    name: "Infopoint list",
    component: () => import("../views/menu/infoPoint/list/infoPoint_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/infopoint/detail/:id",
    name: "Infopoint Detail",
    component: () =>
      import("../views/menu/infoPoint/detail/infoPoint_detail.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/infopoint/edit/:id",
    name: "Infopoint Edit",
    component: () => import("../views/menu/infoPoint/edit/infoPoint_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/infopoint/new",
    name: "Infopoint New",
    component: () => import("../views/menu/infoPoint/new/infoPoint_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //#endregion

  //#region PRIVACY
  {
    path: "/privacy/list",
    name: "Privacy list",
    component: () => import("../views/menu/privacy/list/privacy_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/privacy/new",
    name: "Privacy new",
    component: () => import("../views/menu/privacy/new/privacy_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/privacy/revisions/:idPrivacy/list",
    name: "Privacy Revisions list",
    component: () => import("../views/menu/privacy/revisions/list/privacyRev_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/privacy/revisions/:idPrivacy/new",
    name: "Privacy Revision new",
    component: () => import("../views/menu/privacy/revisions/new/privacyRev_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/privacy/revisions/:idPrivacy/edit/:idPrivacyRev",
    name: "Privacy Revision edit",
    component: () => import("../views/menu/privacy/revisions/edit/privacyRev_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },

  //#endregion

  //#region STAND
  {
    path: "/stand/list",
    name: "Stand list",
    component: () => import("../views/menu/stand/list/stand_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/stand/detail/:id",
    name: "Stand Detail",
    component: () =>
      import("../views/menu/stand/detail/stand_detail.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/stand/edit/:id",
    name: "Stand Edit",
    component: () => import("../views/menu/stand/edit/stand_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/stand/clone/:id",
    name: "Stand Clone",
    component: () => import("../views/menu/stand/clone/stand_clone.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/owners/:id",
    name: "Stand owners",
    component: () => import("../views/menu/stand/owner/owner_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/stand/new",
    name: "Stand New",
    component: () => import("../views/menu/stand/new/stand_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //#endregion

  //#region LIVE_SESSIONS
  {
    path: "/live-session/list/:idParent/:parentName",
    name: "LiveSession_list",
    component: () => import("../views/live-session/list/liveSession_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/live-session/detail/:idParent/:id",
    name: "LiveSession_detail",
    component: () =>
      import("../views/live-session/detail/liveSession_detail.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/live-session/edit/:idParent/:parentName/:id",
    name: "LiveSession_edit",
    component: () => import("../views/live-session/edit/liveSession_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/live-session/new/:idParent/:parentName",
    name: "LiveSession_new",
    component: () => import("../views/live-session/new/liveSession_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //#endregion

  //#region INFO_BOXES
  {
    path: "/info-box/list/:idParent",
    name: "InfoBox_list",
    component: () => import("../views/info-box/list/infoBox_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/info-box/detail/:idParent/:id",
    name: "InfoBox_detail",
    component: () =>
      import("../views/info-box/detail/infoBox_detail.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/info-box/edit/:idParent/:id",
    name: "InfoBox_edit",
    component: () => import("../views/info-box/edit/infoBox_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/info-box/new/:idParent",
    name: "InfoBox_new",
    component: () => import("../views/info-box/new/infoBox_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //#endregion

  //#region ONDEMANDS
  {
    path: "/onDemand/list",
    name: "OnDemand_list",
    component: () => import("../views/menu/onDemand/list/onDemand_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/onDemand/new",
    name: "OnDemand_new",
    component: () => import("../views/menu/onDemand/new/onDemand_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/onDemand/detail/:id",
    name: "OnDemand_detail",
    component: () => import("../views/menu/onDemand/detail/onDemand_detail.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/onDemand/edit/:id",
    name: "OnDemand_edit",
    component: () => import("../views/menu/onDemand/edit/onDemand_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/content/list/:id/:type",
    name: "Content_list",
    component: () => import("../views/content/list/content_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //#endregion

  //#region CONTENTS
  {
    path: "/content/:idParent/:type/new",
    name: "Content_new",
    component: () => import("../views/content/new/content_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/content/:idParent/:type/edit/:id",
    name: "Content_edit",
    component: () => import("../views/content/edit/content_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //#endregion

  //#region CATEGORY
  {
    path: "/category/list",
    name: "Category_list",
    component: () => import("../views/menu/category/list/category_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    },
  },
  {
    path: "/category/new",
    name: "Category_new",
    component: () => import("../views/menu/category/new/category_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    },
  },
  {
    path: "/category/detail/:id",
    name: "Category_detail",
    component: () => import("../views/menu/category/detail/category_detail.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    },
  },
  {
    path: "/category/edit/:id",
    name: "category_edit",
    component: () => import("../views/menu/category/edit/category_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    },
  },
  //#endregion

  //#region TAXONOMY
  {
    path: "/taxonomy/list",
    name: "Taxonomy_list",
    component: () => import("../views/menu/taxonomy/list/taxonomy_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    },
  },
  {
    path: "/taxonomy/new",
    name: "Taxonomy_new",
    component: () => import("../views/menu/taxonomy/new/taxonomy_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    },
  },
  {
    path: "/taxonomy/detail/:id",
    name: "Taxonomy_detail",
    component: () => import("../views/menu/taxonomy/detail/taxonomy_detail.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    },
  },
  {
    path: "/taxonomy/edit/:id",
    name: "Taxonomy_edit",
    component: () => import("../views/menu/taxonomy/edit/taxonomy_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    },
  },
  //#endregion

  //#region TAG
  {
    path: "/taxonomy/:idTaxonomy/tag/list",
    name: "Tag_list",
    component: () => import("../views/menu/taxonomy/tag/list/tag_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    },
  },
  {
    path: "/taxonomy/:idTaxonomy/tag/new",
    name: "Tag_new",
    component: () => import("../views/menu/taxonomy/tag/new/tag_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    },
  },
  {
    path: "/taxonomy/:idTaxonomy/tag/detail/:id",
    name: "Tag_detail",
    component: () => import("../views/menu/taxonomy/tag/detail/tag_detail.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    },
  },
  {
    path: "/taxonomy/:idTaxonomy/tag/edit/:id",
    name: "Tag_edit",
    component: () => import("../views/menu/taxonomy/tag/edit/tag_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    },
  },
  //#endregion

  //#region VISITOR LEVEL
  {
    path: "/visitor-level/list",
    name: "VisitorLevel_list",
    component: () => import("../views/menu/visitorLevel/list/visitorLevel_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/visitor-level/new",
    name: "VisitorLevel_new",
    component: () => import("../views/menu/visitorLevel/new/visitorLevel_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/visitor-level/detail/:id",
    name: "VisitorLevel_detail",
    component: () => import("../views/menu/visitorLevel/detail/visitorLevel_detail.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/visitor-level/edit/:id",
    name: "VisitorLevel_edit",
    component: () => import("../views/menu/visitorLevel/edit/visitorLevel_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //#endregion

  //#region MENU
  {
    path: "/menu/list",
    name: "menu_list",
    component: () => import("../views/menu/menu/list/menu_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/menu/new",
    name: "menu_new",
    component: () => import("../views/menu/menu/new/menu_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/menu/detail/:id",
    name: "menu_detail",
    component: () => import("../views/menu/menu/detail/menu_detail.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/menu/edit/:id",
    name: "menu_edit",
    component: () => import("../views/menu/menu/edit/menu_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/menu/clone/:id",
    name: "menu_clone",
    component: () => import("../views/menu/menu/clone/menu_clone.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //#endregion

  //#region MENU FIELD
  {
    path: "/menu-fields/:idParent/list",
    name: "menuField_list",
    component: () => import("../views/menu/menu/menu-field/list/menuField_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/menu-fields/:idParent/new",
    name: "menuField_new",
    component: () => import("../views/menu/menu/menu-field/new/menuField_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/menu-fields/:idParent/detail/:id",
    name: "menuField_detail",
    component: () => import("../views/menu/menu/menu-field/detail/menuField_detail.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/menu-fields/:idParent/edit/:id",
    name: "menuField_edit",
    component: () => import("../views/menu/menu/menu-field/edit/menuField_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //#endregion

  //#region Gallery
  {
    path: "/gallery/list",
    name: "gallery_list",
    component: () => import("../views/menu/gallery/list/gallery_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/gallery/new",
    name: "gallery_new",
    component: () => import("../views/menu/gallery/new/gallery_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/gallery/detail/:id",
    name: "gallery_detail",
    component: () => import("../views/menu/gallery/detail/gallery_detail.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/gallery/edit/:id",
    name: "gallery_edit",
    component: () => import("../views/menu/gallery/edit/gallery_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //#endregion

  //#region PlatformConfiguration
  {
    path: "/configurations/platform-configuration/edit",
    name: "PlatformConfiguration_edit",
    component: () =>
      import(
        "../views/menu/configurations/platform-configuration/edit/platformConfiguration_edit.vue"
      ),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //#endregion

  //#region Translations
  {
    path: "/configurations/translations/edit",
    name: "Translations_edit",
    component: () =>
      import(
        "../views/menu/configurations/translations/edit/translations_edit.vue"
      ),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //#endregion

  //#region Emails
  {
    path: "/configurations/emails/list/:tab?",
    name: "Emails_list",
    component: () =>
      import(
        "../views/menu/configurations/emails/list/emails_list.vue"
      ),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/configurations/emails/layouts/new",
    name: "EmailLayouts_new",
    component: () =>
      import(
        "../views/menu/configurations/emails/layouts/new/emailLayout_new.vue"
      ),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/configurations/emails/layouts/edit/:id",
    name: "EmailLayouts_edit",
    component: () =>
      import(
        "../views/menu/configurations/emails/layouts/edit/emailLayout_edit.vue"
      ),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/configurations/emails/templates/new",
    name: "EmailTemplates_new",
    component: () =>
      import(
        "../views/menu/configurations/emails/templates/new/emailTemplate_new.vue"
      ),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/configurations/emails/templates/edit/:id",
    name: "EmailTemplates_edit",
    component: () =>
      import(
        "../views/menu/configurations/emails/templates/edit/emailTemplate_edit.vue"
      ),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //#endregion

  //#region CustomField
  {
    path: "/configurations/custom-fields/list",
    name: "CustomField_list",
    component: () => import("../views/menu/configurations/customFields/list/customField_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/configurations/custom-fields/new",
    name: "CustomField_new",
    component: () =>
      import(
        "../views/menu/configurations/customFields/new/customField_new.vue"
      ),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/configurations/custom-fields/edit/:id",
    name: "CustomField_edit",
    component: () =>
      import(
        "../views/menu/configurations/customFields/edit/customField_edit.vue"
      ),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/configurations/custom-fields/detail/:id",
    name: "CustomField_detail",
    component: () =>
      import(
        "../views/menu/configurations/customFields/detail/customField_detail.vue"
      ),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //#endregion

  //#region GALLERY IMAGE
  {
    path: "/gallery-images/:idParent/list",
    name: "galleryImage_list",
    component: () => import("../views/menu/gallery/gallery-image/list/galleryImage_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/gallery-images/:idParent/new",
    name: "galleryImage_new",
    component: () => import("../views/menu/gallery/gallery-image/new/galleryImage_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/gallery-images/:idParent/detail/:id",
    name: "galleryImage_detail",
    component: () => import("../views/menu/gallery/gallery-image/detail/galleryImage_detail.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/gallery-images/:idParent/edit/:id",
    name: "galleryImage_edit",
    component: () => import("../views/menu/gallery/gallery-image/edit/galleryImage_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //#endregion

  //#region InfoCardPage
  {
    path: "/info-card-pages/list",
    name: "infoCardPage_list",
    component: () => import("../views/menu/info-card-page/list/infoCardPage_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/info-card-pages/new",
    name: "infoCardPage_new",
    component: () => import("../views/menu/info-card-page/new/infoCardPage_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/info-card-pages/detail/:id",
    name: "infoCardPage_detail",
    component: () => import("../views/menu/info-card-page/detail/infoCardPage_detail.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/info-card-pages/edit/:id",
    name: "infoCardPage_edit",
    component: () => import("../views/menu/info-card-page/edit/infoCardPage_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //#endregion

  //#region InfoCard
  {
    path: "/info-card-pages/:idInfoCardPage/cards/list",
    name: "infoCard_list",
    component: () => import("../views/menu/info-card-page/info-card/list/infoCard_list.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/info-card-pages/:idInfoCardPage/cards/new",
    name: "infoCard_new",
    component: () => import("../views/menu/info-card-page/info-card/new/infoCard_new.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/info-card-pages/:idInfoCardPage/cards/detail/:id",
    name: "infoCard_detail",
    component: () => import("../views/menu/info-card-page/info-card/detail/infoCard_detail.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/info-card-pages/:idInfoCardPage/cards/edit/:id",
    name: "infoCard_edit",
    component: () => import("../views/menu/info-card-page/info-card/edit/infoCard_edit.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  {
    path: "/info-card-pages/:idInfoCardPage/cards/clone/:id",
    name: "infoCard_clone",
    component: () => import("../views/menu/info-card-page/info-card/clone/infoCard_clone.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
  //#endregion
  {
    path: "/media",
    name: "Media",
    component: () =>
      import("../views/menu/media/media.vue"),
    beforeEnter: (to, from, next) => {
      auth.isLogged() ? next() : router.push("/login");
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: _env("BASE_URL"),
  routes
});

export default router;
