import { ApiHandler } from "@/lib/ApiHandler";
//import { Utility } from "@/lib/Utility.js";

class VisitorLevelService extends ApiHandler {
  constructor() {
    super("/api/visitorLevels");
  }

  async list() {
    return await this.get("")
      .then(res => res.json())
      .then(res => {
        if (!res?.result)
          return res.result;

        res.result.forEach((item) => {
          item.isSystem = !item.isDeletable ? true : false;
        });

        return res.result;
      });
  }

  async enabledLevels (idEntity) {
    return await this.get(`enabled/${idEntity}`)
      .then(res => res.json())
      .then(res => res.result);
  }

  async enabledUserLevels (idUser) {
    return await this.get(`enabled-on-user/${idUser}`)
      .then(res => res.json())
      .then(res => res.result);
  }

  async read (id) {
    return await this.get(id)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async create(body) {
    return await this.post("", body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async update(id, body) {
    return await this.put(id, body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async setEnabling(id, status) {
    return await this.patch(`${id}/${status}`)
      .then(res => res.json())
      .then(res => res);
  }

}

export const visitorLevelService = new VisitorLevelService();
