import { ApiHandler } from "@/lib/ApiHandler";

class MenuService extends ApiHandler {
  constructor () {
    super("/api/menus");
  }

  async icons () {
    return await this.get("icons")
      .then(res => res.json())
      .then(res => res.result);
  }

  async list () {
    return await this.get("")
      .then(res => res.json())
      .then(res => res.result);
  }

  async read (id) {
    return await this.get(id)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async create (body) {
    return await this.post("", body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async update (id, body) {
    return await this.put(id, body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async setEnabled (idMenu, status) {
    return await this.patch(`${idMenu}/${status}`)
      .then(res => res.json())
      .then(res => res);
  }

  async saveOrder (orderedMenus) {
    return await this.put("updateOrder", orderedMenus)
      .then(res => res.json())
      .then(res => res);
  }

  async readField (idMenu, idMenuField) {
    return await this.get(`${idMenu}/fields/${idMenuField}`)
      .then(res => res.json())
      .then(res => res.result);
  }

  async createField (idMenu, body) {
    return await this.post(`${idMenu}/fields`, body)
      .then(res => res.json())
      .then(res => res.result);
  }

  async updateField (idMenu, idMenuField, body) {
    return await this.put(`${idMenu}/fields/${idMenuField}`, body)
      .then(res => res.json())
      .then(res => res.result);
  }

  async deleteField (idMenu, idMenuField) {
    return await this.delete(`${idMenu}/fields/${idMenuField}`)
      .then(res => res.json())
      .then(res => res.result);
  }

  async saveFieldsOrder (idMenu, orderedFields) {
    return await this.put(`${idMenu}/updateOrder`, orderedFields)
      .then(res => res.json())
      .then(res => res);
  }

  async clone (idMenu, body) {
    return await this.post(`${idMenu}/clone`, body)
      .then(res => res.json())
      .then(res => res);
  }
}

export const menuService = new MenuService();
