export class StrUtility {
  // split a string into an array of words
  static words(str) {
    return str
      .replace(/([a-z]|[0-9])([A-Z])/g, "$1 $2")
      .split(/[\s\-_'",.:!?|]/g);
  }

  static upperFirst(str) {
    return str.replace(/^(.)/, (c) => { return c.toUpperCase(); });
  }

  static lowerFirst(str) {
    return str.replace(/^(.)/, (c) => { return c.toLowerCase(); });
  }

  // camelCase
  static camelCase(str) {
    return this.words(str)
      .map(w => w.toLowerCase())
      .map((word, index) => {
        return (index == 0)
          ? this.lowerFirst(word)
          : this.upperFirst(word);
      }).join("");
  }

  // PascalCase
  static pascalCase(str) {
    return this.words(str)
      .map(w => w.toLowerCase())
      .map(word => {
        return this.upperFirst(word);
      }).join("");
  }

  // custom{glue}case
  static customCase(str, glue) {
    return this.words(str)
      .map(s => s.toLowerCase())
      .join(glue);
  }

  // snake_case
  static snakeCase(str) {
    return this.customCase(str, "_");
  }

  // kebab-case
  static kebabCase(str) {
    return this.customCase(str, "-");
  }

  static stripEnd(str, needle = " ") {
    const pattern = Array.isArray(needle)
      ? needle.join("|")
      : needle;
    const rx = new RegExp(`(${pattern})+$`);
    return str.replace(rx, "");
  }

  static stripStart(str, needle = " ") {
    const pattern = Array.isArray(needle)
      ? needle.join("|")
      : needle;
    const rx = new RegExp(`^(${pattern})+`);
    return str.replace(rx, "");
  }

  static stripBoth(str, needle = " ") {
    return this.stripEnd(
      this.stripStart(str, needle),
      needle
    );
  }

  static stripRight(str, needle) {
    return this.stripEnd(str, needle);
  }

  static stripLeft(str, needle) {
    return this.stripStart(str, needle);
  }

  static pathJoin(...pieces) {
    return pieces.map(p => p ? this.stripBoth(p.trim(), "/").trim() : "").join("/");
  }
}
