export const state = {
  list: null,
  auditorium: null
};

export const mutations = {
  SAVE_AUDITORIUM_LIST(state, list) {
    state.list = list;
  },
  RESET_AUDITORIUM_LIST(state) {
    state.list = null;
  },

  SAVE_AUDITORIUM(state, element) {
    state.auditorium = element;
  },
  RESET_AUDITORIUM(state) {
    state.auditorium = null;
  }
};
