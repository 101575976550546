import {
  EventBus
} from "@/lib/EventBus";

class ToastService {

  success = (text, durationSeconds = 3) => {
    this.showToast(text, durationSeconds, "success");
  }

  error = (text, durationSeconds = 3) => {
    this.showToast(text, durationSeconds, "error");
  }

  showToast(text, durationSeconds, type) {
    let toastBody = {
      text : text,
      duration : durationSeconds * 1000
    };
    EventBus.$emit(type, toastBody);
  }
}

export const toastService = new ToastService();