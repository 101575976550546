import { localStorageService } from "@/services/localStorageService.js";
import { StrUtility } from "@/lib/StrUtility";
import _env from "@/plugins/env";

export class Utility {
  static getFilePath(file) {
    if (file && file.startsWith("http")) {
      return file;
    }
    const prefix = _env("VUE_APP_API_BASE_URL") + "/Files/";
    if (!file) {
      return null;
    }
    return file.startsWith(prefix)
      ? file
      : _env("VUE_APP_API_BASE_URL") + "/Files/" + file;
  }

  static getFileExtension(url) {
    return url?.split(/[#?]/)[0].split(".").pop().trim();
  }

  static isAnImage(url) {
    const extension = this.getFileExtension(url);
    return extension === "jpg" ||
      extension === "jpeg" ||
      extension === "png" ||
      extension === "svg";
  }


  static toFormType(rawType, value) {
    const prefix = rawType.startsWith("multilang:")
      ? "multilang:"
      : "";
    const type = rawType.startsWith("multilang:")
      ? rawType.replace("multilang:", "")
      : rawType;
    switch (type) {
      case "bool":
        return prefix + "switch";
      case "string":
        return prefix + "text";
      case "int":
        return prefix + "number";
      case "file":
        if (value.startsWith("http"))
          return prefix + "text";
        return prefix + "media";
      case "json":
        return prefix + "textarea";
      case "select":
        return prefix + "select";
      case "text":
        return prefix + "textarea";
    }
  }


  static toStringConverter(type, value) {
    switch (type) {
      case "bool":
        if (value == true)
          return "1";
        else
          return "0";
      case "json":
        return value.replace("\r\n", "");
      case "int":
        return value.toString();
      default:
        return value;
    }
  }

  static fromStringConverter(type, value) {
    if (type.startsWith("multilang:")) {
      try {
        return JSON.parse(value);
      } catch (_) {
        return {};
      }
    }
    switch (type) {
      case "bool":
        if (value == "1")
          return true;
        else
          return false;
      case "int":
        return parseInt(value);
      default:
        return value;
    }
  }

  static getIconFromExtension(url) {
    switch (this.getFileExtension(url)) {
      case "pdf":
        return "Pdf";
      case "xls":
      case "xlsx":
        return "Xls";
      case "ppt":
        return "Ppt";
      case "doc":
      case "docx":
        return "Doc";
      case "zip":
        return "Zip";
      default:
        return null;
    }
  }

  /**
  * Retrieves a value from an object passing the key in dot-notation.
  *
  * i.e.:
  * const config = {
  *    platform: {
  *      title: "test"
  *    }
  * }
  *
  * config("platform.title", config); // returns test
  *
  * @param {string} key to search in the config object
  * @param {object} configs object
  */
  static extractFromObj(key, obj) {
    if (!obj) {
      return null;
    }
    const keys = key.split(".", 2);
    key = keys[0];
    if (!obj[key]) {
      return null;
    }
    if (keys.length > 1) {
      return this.extractFromObj(keys[1], obj[key]);
    }
    return obj[key];
  }

  static readableDate(value) {
    const options = { weekday: "long", month: "long", day: "numeric" };
    return new Date(value).toLocaleDateString(localStorageService.getCurrentLang(), options);
  }

  static formatDate(value) {
    const dateObj = new Date(value);
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObj.getDate().toString().padStart(2, "0");
    return `${dateObj.getFullYear()}-${month}-${day}`;
  }

  static formatDateLocale(utcDate) {
    return (new Date(utcDate)).toLocaleString();
  }

  static formatTime(value) {
    const dt = new Date(value);
    return `${(dt.getHours() < 10 ? "0" : "") + dt.getHours()}:${(dt.getMinutes() < 10 ? "0" : "") + dt.getMinutes()}`;
  }

  static formatDateTime(value) {
    return this.formatDate(value) + " " + this.formatTime(value);
  }

  static isValidDate(date) {
    return date instanceof Date && !isNaN(date);
  }

  static clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  static objectSignature(o) {
    let s = (o) => {
      if (o === null || o === undefined) {
        return o;
      }
      return Object.entries(o).sort().map(i => {
        if (i[1] instanceof Object) {
          i[1] = s(i[1]);
        }
        return i;
      });
    };
    return JSON.stringify(s(o));
  }

  static equalObjects(a, b) {
    return this.objectSignature(a) === this.objectSignature(b);
  }

  // static counter for uids with default value
  static _uid = (new Date()).getTime();

  /**
   * Returns a unique id (local to the client)
   * @param {string} prefix uid prefix
   * @param {string} format format of the uid; the character `*` is changed to a random character
   *                        (or the prefix), while all other characters remain unchanged;
   *                        defaults to:
   *                                      ********-****-****-****-************
   */
  static uid(prefix, format = "********-****-****-****-************") {
    // just to be sure, we concatenate a random string to a unique counter to a series of random strings
    const length = format.length;
    const formatChars = format.split("");
    const padding = (new Array((length / 9) | 0)).fill("");
    const id = (prefix || "")
      + (this._uid++)
      + padding.map(() => {
        return Math.random().toString(36).substr(2, 9);
      }).join("");
    const idChars = id.split("");
    const generatedId = formatChars.map((c, i) => {
      if (i > length) {
        return "";
      }
      if (c === "*") {
        if (typeof idChars[i] === "undefined") {
          return "0";
        }
        return idChars[i];
      }
      return formatChars[i];
    });
    return generatedId.join("");
  }

  static timeout;

  /**
   * Debounce function based on:
   *    https://davidwalsh.name/javascript-debounce-function
   * limits the rate at which a function can be fired.
   * @param {callable} func the function to call
   * @param {number} wait wait in milliseconds
   * @param {boolean} immediate (default: false) trigger the function on the leading edge, instead of the trailing
   */
  static debounce(func, wait, immediate) {
    return () => {
      let context = this, args = arguments;
      const later = () => {
        this.timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      const callNow = immediate && !this.timeout;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(later, wait);
      if (callNow) {
        func.apply(context, args);
      }
    };
  }

  /**
   * Exports a list in csv.
   * @param {array} columns File headers
   * @param {array} rows File content lines
   * @param {string} filename Suggested filename
   * @param {string} rows Char separator (defaults to ';')
   */
  static exportToCsv(columns, rows, filename = null, separator = ";") {
    if (filename == null)
      filename = (new Date()).toISOString().substr(0, 16).replace(/[-T:.]/g, "") + "_export.csv";

    let body = [];
    for (const i in rows) {
      const row = rows[i];
      let line = [];
      columns.forEach(col => {
        let value;
        if (typeof col === "object") {
          const colName = col.name;
          const rawColName = `__raw__${colName}`;
          if (typeof row[rawColName] !== "undefined") {
            value = row[rawColName];
          } else {
            value = col.value(row, colName, col);
          }
        } else {
          value = typeof row[col] !== "undefined"
            ? row[col]
            : "";
          if (typeof row[col] === "string") {
            value = value.replace("\"", "\"");
          }
        }

        if (value === null) {
          value = "- - -";
        }

        line.push(`"${value}"`);
      });
      body.push(line);
    }

    let csvContent = body.map(l => l.join(separator)).join("\n");

    this.exportDownloadFile(csvContent, filename);
  }

  /**
   * Exports a list in csv.
   * @param {array} array composed of arrays i.e. headers + rows
   * @param {string} filename Suggested filename
   * @param {string} rows Char separator (defaults to ';')
   */
  static exportArrayToCsv(array, filename = null, separator = ";") {
    let csvContent = "";

    if (filename == null)
      filename = (new Date()).toISOString().substr(0, 16).replace(/[-T:.]/g, "") + "_export.csv";

    array.forEach((rowArray) => {
      let line = [];
      rowArray.forEach((elem) => {
        let value = typeof elem !== "undefined"
          ? elem
          : "";
        if (typeof elem === "string") {
          value = value.replace("\"", "\"");
        }

        if (value === null) {
          value = "- - -";
        }

        line.push(`"${value}"`);
      });
      let row = rowArray.join(separator);

      csvContent += row + "\r\n";
    });

    this.exportDownloadFile(csvContent, filename);
  }

  static exportDownloadFile(content, filename) {
    // create blob file
    const blob = new Blob([content], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // create fake link element
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";

    // add fake link to DOM
    document.body.appendChild(link);
    // trigger download
    link.click();
    // remove fake link from DOM
    document.body.removeChild(link);
  }

  static exportResults(labels, context, columns, rows, filename = null, separator = ";") {
    let headers = {};

    columns.forEach(rawCol => {
      const rawColName = typeof rawCol === "object"
        ? rawCol.name
        : rawCol;
      const col = typeof rawCol === "object"
        ? `__raw__${rawColName}`
        : rawCol;
      const labelName = `export${StrUtility.upperFirst(context)}_${col}`;
      headers[col] = labels[labelName] ?? rawColName;
    });
    const rowsWithHeaders = [headers, ...rows];

    Utility.exportToCsv(columns, rowsWithHeaders, filename, separator);

  }

  static groupByKey(array, key) {
    return array
      .reduce((hash, obj) => {
        if (obj[key] === undefined) return hash;
        return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) });
      }, {});
  }

  static intersect(arr1, arr2) {
    return arr1.filter(a => arr2.includes(a));
  }

  static swapItems(arr, oldIndex, newIndex) {
    arr.splice(
      newIndex,
      0,
      ...arr.splice(
        oldIndex,
        1
      )
    );
  }

  static moveElementInArray(arr, oldIndex, newIndex){
    let arrCopy = [...arr];
    arrCopy.splice(oldIndex, 1);
    arrCopy.splice(newIndex, 0, arr[oldIndex]);
    return arrCopy;
  }

  /**
   * Use this method to conditionally add an element in an array.
   * Used like:
   *
   *      [
   *          "value1",
   *          ...Utility.addIf("value2", otherValue == true),
   *          "value3"
   *      ]
   *
   * The resulting array will be:
   *      ["value1","value2","value3"]
   * if `otherValue` is `true`
   *      ["value1","value3"]
   * otherwise
   * @param {any} element The element to add
   * @param {boolean} condition Bool value that specifies when the element must be added
   */
  static addIf(element, condition) {
    return condition
      ? [element]
      : [];
  }

  static hideIf(element, condition) {
    return condition
      ? []
      : [element];
  }

  static distinct(arr) {
    return arr.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  }

  static generateEntityLink(event, type, val = null, queryParams = {}) {
    const eventCode = event?.code ?? "";
    const query = Object.keys(queryParams).map(k => {
      return `${k}=${queryParams[k]}`;
    }).join("&");
    const pieces = [
      _env("VUE_APP_VIRTUAL_PLACE_URL"),
      `event-${eventCode}`,
      type,
      ...(val ? [val] : [])
    ];
    return StrUtility.pathJoin(...pieces) + (query ? `?${query}` : "");
  }
}
