export const state = {
  list: null,
  hall: null,
  links: {
    list: null
  }
};

export const mutations = {
  SAVE_LIST_HALLS(state, list) {
    state.list = list;
  },
  SAVE_HALL(state, hall) {
    state.hall = hall;
  },
  //LINK RELATIVI ALLE HALL
  SAVE_HALLS_LINKS_LIST(state, list) {
    state.links.list = list;
  }
};
