export const state = {
  list: null,
  event: null,
  eventLanguages: null
};

export const mutations = {
  SAVE_EVENTS_LIST(state, list) {
    if (list) {
      list.map(item => {
        item.startDate = item.startDate.substr(0, 10);
        item.endDate = item.endDate.substr(0, 10);
        item.publishDate = item.publishDate
          ? item.publishDate.substr(0, 10)
          : "---";
      });
    }

    state.list = list;
  },
  SAVE_EVENT(state, event) {
    event.startDate = event.startDate.substr(0, 10);
    event.endDate = event.endDate.substr(0, 10);
    event.publishDate = event.publishDate
      ? event.publishDate.substr(0, 10)
      : null;
    state.event = event;
  },
  SAVE_EVENT_LANGUAGES_LIST(state, eventLanguages) {
    state.eventLanguages = eventLanguages;
  },
  RESET_EVENT_LANGUAGES_LIST(state) {
    state.eventLanguages = null;
  }
};
