import {
  ApiHandler
} from "@/lib/ApiHandler";
import {
  Utility
} from "@/lib/Utility.js";

class EventService extends ApiHandler {
  constructor() {
    super("/api/events");
  }

  async list() {
    return await this.get("")
      .then(res => res.json())
      .then(res => res.result);
  }

  async read(id) {
    return await this.get(id)
      .then(res => res.json())
      .then((res) => {
        if (res.result.imageUri != undefined)
          res.result.imageUri = Utility.getFilePath(res.result.imageUri);
        if (res.result.links) {
          res.result.links.forEach((link) => {
            if (link.icon != undefined)
              link.icon = Utility.getFilePath(link.icon);
          });
        }
        return res.result;
      });
  }

  async create(body) {
    return await this.post("", body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async update(id, body) {
    return await this.put(id, body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async setEnabling(idEvent, status) {
    return await this.patch(`${idEvent}/${status}`)
      .then(res => res.json())
      .then(res => res);
  }

  async readConfig(id) {
    return await this.get(`${id}/configurations`)
      .then(res => res.json())
      .then((res) => {
        if (res.result.imageUri != undefined)
          res.result.imageUri = Utility.getFilePath(res.result.imageUri);
        if (res.result.links) {
          res.result.links.forEach((link) => {
            if (link.icon != undefined)
              link.icon = Utility.getFilePath(link.icon);
          });
        }
        return res.result;
      });
  }

  async saveConfig(id, configs) {
    return await this.post(`${id}/configurations`, configs)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async relatedLanguages(idRelated) {
    return await this.get(`related/${idRelated}/languages`)
      .then(res => res.json())
      .then(res => res.result);
  }

  async agendaLayout() {
    return await this.get("agendaLayout")
      .then(res => res.json())
      .then(res => res.result);
  }
}

export const eventService = new EventService();