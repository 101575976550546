import { ApiHandler } from "@/lib/ApiHandler";

class PassCodeService extends ApiHandler {
  constructor () {
    super("/api/passCodes");
  }

  async generate(entityType, id, howMany) {
    return await this.post(`${entityType}/${id}`, { howMany })
      .then(res => res.json())
      .then(res => res.result);
  }

  async export(entityType, id) {
    return await this.get(`export/${entityType}/${id}`)
      .then(res => res.json())
      .then(res => res.result);
  }
}

export const passCodeService = new PassCodeService();
