import { ApiHandler } from "@/lib/ApiHandler";

class ContentService extends ApiHandler {
  constructor () {
    super("/api/contents");
  }

  async list (idParent,type) {
    return await this.get("", { idParent,type })
      .then(res => res.json())
      .then(res => res.result);
  }

  async updateOrder (param) {
    return await this.put("updateOrder", param)
      .then(res => res.json())
      .then(res => res.result);
  }

  async read (id) {
    return await this.get(id)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async create (body) {
    return await this.post("", body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async update (id, body) {
    return await this.put(id, body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }
  async remove (idContent) {
    return await  this.delete(idContent)
    .then(res => res.json())
    .then(res => res.result);
  }

  async types () {
    return await this.get("types")
      .then(res => res.json())
      .then(res => res.result);
  }
}

export const contentService = new ContentService();
