import languages from "@/assets/languages.json";

export const state = {
  formFieldList: null,
  languageList: languages,
  activeLanguageList: [],
  contextualLanguageList: [],
  linksDefaultWidth: null
};

export const mutations = {
  SET_FORM_FIELDS(state, _payload) {
    state.formFieldList = _payload;
  },
  SET_LANGUAGES(state, languages) {
    state.activeLanguageList = languages;
  },
  SET_CONTEXTUAL_LANGUAGES(state, languages) {
    state.contextualLanguageList = languages;
  },
  RESET_CONTEXTUAL_LANGUAGES(state) {
    state.contextualLanguageList = [];
  },
  SAVE_DEFAULT_POPUP_WIDTH(state, value) {
    state.linksDefaultWidth = value;
  }
};
