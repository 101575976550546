import { storageService } from "@/services/storageService";

class LocalStorageService {
  // Base
  get (name, defaulValue = null) {
    return storageService.getLocal(name, defaulValue);
  }

  set (name, value) {
    storageService.setLocal(name, JSON.stringify(value));
  }

  remove (name) {
    storageService.removeLocal(name);
  }

  getLoggedUser () {
    return storageService.getLocal("loggedUser");
  }

  setLoggedUser (credentials) {
    storageService.setLocal("loggedUser", credentials);
  }

  removeLoggedUser () {
    storageService.removeLocal("loggedUser");
  }

  getCurrentLang () {
    return storageService.getLocal("currentLang", "en");
  }

  setCurrentLang (lang) {
    storageService.setLocal("currentLang", lang);
  }

  removeCurrentLang () {
    storageService.removeLocal("currentLang");
  }

  getCurrentEventId () {
    return storageService.getLocal("currentEventId");
  }

  setCurrentEventId (eventId) {
    storageService.setLocal("currentEventId", eventId);
  }

  removeCurrentEventId () {
    storageService.removeLocal("currentEventId");
  }

  getStartingContentAlreadyViewed () {
    return storageService.getLocal("startingContentAlreadyViewed");
  }

  setStartingContentAlreadyViewed (status) {
    storageService.setLocal("startingContentAlreadyViewed", status);
  }

  removeStartingContentAlreadyViewed () {
    storageService.removeLocal("startingContentAlreadyViewed");
  }
}

export const localStorageService = new LocalStorageService();
