import { ApiHandler } from "@/lib/ApiHandler";

class LanguageService extends ApiHandler {
  constructor () {
    super("/api/languages");
  }

  async list () {
    return await this.get("")
      .then(res => res.json())
      .then(res => res.result);
  }

  async save (body) {
    return await this.post("",body)
      .then(res => res.json())
      .then(res => res.result);
  }

  async remove (id) {
    return await this.delete(id)
      .then(res => res.json())
      .then(res => res.result);
  }

  async setAsDefault (id) {
    return await this.patch(`${id}/as-default`)
      .then(res => res.json())
      .then(res => res.result);
  }

  async setNotAsDefault (id) {
    return await this.patch(`${id}/not-as-default`)
      .then(res => res.json())
      .then(res => res.result);
  }
}

export const languageService = new LanguageService();