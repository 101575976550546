import { ApiHandler } from "@/lib/ApiHandler";
import { Utility } from "@/lib/Utility";

class FileUploadService extends ApiHandler {
  uploadingFiles = {};

  constructor () {
    super("/api/files");
    this.uploadingFiles = {};
  }

  isUploading () {
    if (!this.uploadingFiles) {
      return false;
    }
    return Object.keys(this.uploadingFiles).length > 0;
  }

  // eslint-disable-next-line no-unused-vars
  async upload (file, onProgress = null) {
    // eslint-disable-next-line no-empty-function
    onProgress = onProgress ?? (() => {});
    let formData = new FormData();
    formData.append("file", file);

    const fileUploadId = Utility.uid("fu");
    this.uploadingFiles[fileUploadId] = true;

    const uploadResult = await this.post("", {}, { body: formData, uploadFile: true })
      .then(res => res.json())
      .then(res => res.result);

    delete this.uploadingFiles[fileUploadId];

    return uploadResult;
  }

}

export const fileUploadService = new FileUploadService();