import { ApiHandler } from "@/lib/ApiHandler";

class UIService extends ApiHandler {
  constructor() {
    super("/api/ui");
  }

  async menu(options = {}) {
    const cachebust = options?.cachebust;
    const endpoint = "menu" + (cachebust ? "?" + Date.now() : "");

    return await this.get(endpoint)
      .then(res => res.json())
      .then(res => res.result);
  }

  async ui(entityType) {
    return await this.get(`entity-ui/${entityType}`)
      .then(res => res.json())
      .then(res => res.result);
  }

  async uiFormHide(entityType) {
    return await this.get(`form-actions-ui/${entityType}/to-hide`)
      .then(res => res.json())
      .then(res => res.result);
  }
}

export const uiService = new UIService();