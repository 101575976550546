import { ApiHandler } from "@/lib/ApiHandler";

class LiveSessionService extends ApiHandler {
  constructor () {
    super("/api/liveSessions");
  }

  async list (idParent) {
    return await this.get(`?idParent=${idParent}`)
      .then(res => res.json())
      .then(res => res.result);
  }

  async read (id) {
    return await this.get(id)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async create (body) {
    return await this.post("", body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async update (idLivesession, body) {
    return await this.put(`${idLivesession}`, body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async enable (id) {
    return await this.patch(`${id}/enable`, {})
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async disable (id) {
    return await this.patch(`${id}/disable`, {})
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async setAvailable (id) {
    return await this.patch(`${id}/available`, {})
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async setNotAvailable (id) {
    return await this.patch(`${id}/not-available`, {})
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async types () {
    return await this.get("types")
      .then(res => res.json())
      .then(res => res.result);
  }

  async platforms () {
    return await this.get("platforms")
      .then(res => res.json())
      .then(res => res.result);
  }

  async openingOptions () {
    return await this.get("openingOptions")
      .then(res => res.json())
      .then(res => res.result);
  }

  async exportParticipants(id) {
    return await this.get(`export/participants/${id}`);
  }
}

export const liveSessionService = new LiveSessionService();

