import {
  ApiHandler
} from "@/lib/ApiHandler";
import {
  Utility
} from "@/lib/Utility.js";

class OnDemandService extends ApiHandler {
  constructor() {
    super("/api/onDemands");
  }

  async list() {
    return await this.get("")
      .then(res => res.json())
      .then(res => res.result);
  }

  async create(param) {
    return await this.post("", param)
      .then(res => res.json())
      .then(res => res.result);
  }

  async setEnabling(idOnDemand, status) {
    return await this.patch(`${idOnDemand}/${status}`)
      .then(res => res.json())
      .then(res => res);
  }

  async read(id) {
    return await this.get(id)
      .then(res => res.json())
      .then((res) => {
        if (res.result != undefined && res.result != null)
          res.result.imageUri = Utility.getFilePath(res.result.imageUri);
        return res.result;
      });
  }

  async update(id, body) {
    return await this.put(id, body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }
}

export const onDemandService = new OnDemandService();