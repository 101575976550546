import Vue from "vue";
import Vuex from "vuex";

import * as authStore from "./authStore.js";
import * as linkStore from "./linkStore.js";
import * as app_config_store from "./app_config_store.js";
import * as navigationStore from "./utlility/navigationStore.js";
import * as toastStore from "./utlility/toastStore.js";
import * as configurationStore from "./pages/configurationStore.js";
import * as translationsStore from "./utlility/translationsStore.js";
import * as hallsStore from "./pages/hallStore.js";
import * as eventStore from "./pages/eventStore.js";
import * as infoPointStore from "./pages/infoPointStore.js";
import * as auditoriumStore from "./pages/auditoriumStore.js";
import * as liveSessionStore from "./liveSessionStore.js";
import * as bulkStore from "./bulkStore.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app_config_store,
    authStore,
    configurationStore,
    hallsStore,
    navigationStore,
    toastStore,
    translationsStore,
    eventStore,
    infoPointStore,
    auditoriumStore,
    liveSessionStore,
    linkStore,
    bulkStore
  }
});
