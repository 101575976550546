var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"title-base",class:{
    main: _vm.type === 1 || undefined,
    secondary: _vm.type === 2,
    thirdary: _vm.type === 3,
    center: _vm.center === 'center' || undefined,
    left: _vm.center === 'left',
    right: _vm.center === 'right',
    bold: _vm.bold
  },style:(_vm.size ? ("font-size:" + _vm.size + "rem") : null)},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }