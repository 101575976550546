<template>
  <div v-if="currentUser.firstName && currentUser.lastName" class="user-info">
    <div class="user-data" @click="toggle()">
      <div class="wrapper-data">
        <h4 class="user-name">
          {{ currentUser.firstName }} {{ currentUser.lastName }}
        </h4>
        <p class="user-role">{{ currentUserGroups }}</p>
      </div>
      <div class="wrapper-icon">
        <Svg-icon icon="user" />
      </div>
    </div>

    <div v-if="show.options" class="user-options">
      <ul>
        <li>
          <router-link
            v-for="(item, index) in panel"
            :key="index"
            :to="item.link"
          >
            {{ item.voice }}
          </router-link>
        </li>

        <li @click="logout()">{{ translations.labels.logout }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import SvgIcon from "../dom/SvgIcon.vue";
import Component from "vue-class-component";

@Component({
  components: {
    SvgIcon,
  },
  inject: {
    authService: "authService",
  },
})
export default class UserInfo {
  show = {
    options: false,
  };
  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }
  get currentUser() {
    return this.$store.state.authStore.currentUser;
  }
  get currentUserGroups() {
    return (this?.currentUser?.groups ?? []).join(", ");
  }
  get panel() {
    return [
      {
        link: "/users/detail",
        voice: this.translations.labels.profile,
      },
    ];
  }
  toggle() {
    this.show.options = !this.show.options;
  }
  logout() {
    localStorage.clear();
    this.$store.commit("RESET_CURRENT_USER");
    this.$router.push("/login");
  }
}
</script>

<style lang="scss" scoped>
.user-info {
  .user-data {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;

    .wrapper-data {
      text-align: right;
      margin-right: 25px;

      .user-name {
        text-transform: uppercase;
        font-size: 1.8rem;
      }

      .user-role {
        font-size: 1.2rem;
      }
    }

    .wrapper-icon {
      .svg-icon {
        width: 50px;
        height: 50px;
      }
    }
  }

  .user-options {
    position: absolute;
    top: 95px;
    right: 0;
    background-color: $white;
    box-shadow: 0px 0px 10px 0px $grey-light;
    width: 200px;
    z-index: 1000;

    ul {
      list-style: none;
      text-align: left;
      padding: 0;

      li {
        color: $main-color;
        font-size: 1.3rem;
        padding: 10px;
        transition: all 0.3s;
        cursor: pointer;

        &:hover {
          background-color: $grey-lighter;
        }

        a {
          color: $main-color;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
