<template>
  <div class="navbar">
    <div class="wrapper-logo">
      <img class="logo" src="@/assets/img/logo.png" alt="Logo FirstRow" />
    </div>

    <NavItems :navItemList="navItemList" />
  </div>
</template>

<script>
import NavItems from "@/components/navbar/NavItems.vue";

export default {
  components: {
    NavItems
  },
  props: {
    navItemList: Array
  }
};
</script>

<style lang="scss" scoped>
.navbar {
  background-color: $main-color;
  color: $white;
  width: 100%;
  height: 100vh;
  overflow-y: auto;

  @media (max-width: $lg) {
    height: 100%;
    padding-bottom: 30px;
  }

  .wrapper-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    border-right: 1px solid $grey-light;
    box-shadow: $box-shadow-light;
    height: 90px;

    .logo {
      max-width: 100%;
      object-fit: contain;
      padding: 20px 30px;
    }
  }
}
</style>
