import { ApiHandler } from "@/lib/ApiHandler";

class TranslationService extends ApiHandler {
  constructor () {
    super("/api/translations");
  }

  async list () {
    return await this.get()
      .then(res => res.json())
      .then(res => res.result);
  }

  async createOrUpdate (idLanguage, context, code, label) {
    return await this.post(`${idLanguage}/${context}/${code}`, { label: label })
      .then(res => res.json())
      .then(res => res.result);
  }
}

export const translationService = new TranslationService();
