import { ApiHandler } from "@/lib/ApiHandler";
import { groupService } from "@/services/groupService";

class PrivacyService extends ApiHandler {
  groupService = null;

  constructor(_groupService) {
    super("/api/privacies");
    this.groupService = _groupService;
  }

  getPrivacyTypes(){
    return [{
      id: "text",
      label: "Text"
    }, {
      id: "simple",
      label: "SimpleFlag"
    }];
  }

  async getRevisions (id) {
    return await this.get(`${id}/revisions`)
      .then(res => res.json())
      .then(res => res.result);
  }

  async create(body) {
    return await this.post("", body)
      .then(res => res.json())
      .then(res => res.result);
  }

  async addRev(idPrivacy,body) {
    return await this.post(`${idPrivacy}`, body)
      .then(res => res.json())
      .then(res => res.result);
  }

  async updateRev(idPrivacy,body) {
    return await this.put(`${idPrivacy}`, body)
      .then(res => res.json())
      .then(res => res.result);
  }

  async list(filters = null) {
    return await this.get("", filters)
      .then(res => res.json())
      .then(res => res.result);
  }

  async allEnabled() {
    return await this.get("enabled")
      .then(res => res.json())
      .then(res => res.result);
  }

  async getRev(idPrivacyRev) {
    return await this.get(idPrivacyRev)
      .then(res => res.json())
      .then((res) => res.result);
  }

  async updateOrder(body) {
    return await this.put("updateOrder",body)
      .then(res => res.json())
      .then((res) => res.result);
  }

  async updateCode(body) {
    return await this.put("updateCode",body)
      .then(res => res.json())
      .then((res) => res.result);
  }

  async setEnabled (id, status) {
    return await this.patch(`${id}/${status}`)
      .then(res => res.json())
      .then(res => res);
  }

  async setAnonymizeDataTracking (id) {
    return await this.patch(`${id}/setAnonymizeDataTracking`)
      .then(res => res.json())
      .then((res) => res.result);
  }

  async unsetAnonymizeDataTracking (id) {
    return await this.patch(`${id}/unsetAnonymizeDataTracking`)
      .then(res => res.json())
      .then((res) => res.result);
  }

  async del (id) {
    return await this.delete(id)
      .then(res => res.json())
      .then(res => res);
  }

  async publishRev (idPrivacy,idPrivacyRev) {
    return await this.patch(`${idPrivacy}/${idPrivacyRev}/publish`)
      .then(res => res.json())
      .then(res => res);
  }
}

export const privacyService = new PrivacyService(groupService);