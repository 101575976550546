export const state = {
  list: null,
  liveSession: null,
  types: null,
  platforms: null,
  openingOptions: null
};

export const mutations = {
  SAVE_LIVE_SESSION_LIST(state, list) {
    if (list) {
      list.map(item => {
        item.startDate = item.startDate.substr(0, 10);
        item.endDate = item.endDate.substr(0, 10);
      });
    }

    state.list = list;
  },
  RESET_LIVE_SESSION_LIST(state) {
    state.list = null;
  },

  SAVE_LIVE_SESSION(state, liveSession) {
    if (liveSession) {
      liveSession.startDate = liveSession.startDate.substr(0, 10);
      liveSession.endDate = liveSession.endDate.substr(0, 10);
    }

    state.liveSession = liveSession;
  },
  RESET_LIVE_SESSION(state) {
    state.liveSession = null;
  },

  SAVE_LIVE_SESSION_TYPES(state, types) {
    state.types = types;
  },
  RESET_LIVE_SESSION_TYPES(state) {
    state.types = null;
  },

  SAVE_LIVE_SESSION_PLATFORMS(state, platforms) {
    state.platforms = platforms;
  },
  RESET_LIVE_SESSION_PLATFORMS(state) {
    state.platforms = null;
  },

  SAVE_LIVE_SESSION_OPENING_OPTIONS(state, openingOptions) {
    state.openingOptions = openingOptions;
  },
  RESET_LIVE_SESSION_OPENING_OPTIONS(state) {
    state.openingOptions = null;
  }
};
