import { ApiHandler } from "@/lib/ApiHandler";
import { Utility } from "@/lib/Utility";

class FileService extends ApiHandler {
  uploadingFiles = {};

  constructor() {
    super("/api/mediafiles");
    this.uploadingFiles = {};
  }

  isUploading () {
    return Object.keys((this?.uploadingFiles ?? {})).length > 0;
  }

  async listInFolder(idFolder) {
    return await this.get(`available-list-by-folder/${idFolder}`)
      .then(res => res.json())
      .then(res => res.result);
  }

  async listDeleted() {
    return await this.get("deleted")
      .then(res => res.json())
      .then(res => res.result);
  }

  async read(id) {
    return await this.get(id)
      .then(res => res.json())
      .then((res) => res.result);
  }

  async readByUrl(url) {
    return await this.post("by-url", { url: url })
      .then(res => res.json())
      .then((res) => res.result);
  }

  // eslint-disable-next-line no-unused-vars
  async create(body, onProgress = null) {
    // eslint-disable-next-line no-empty-function
    onProgress = onProgress ?? (() => {});

    let formData = new FormData();
    for (const prop in body) {
      formData.append(prop, body[prop]);
    }

    const fileUploadId = Utility.uid("fu");
    this.uploadingFiles[fileUploadId] = true;

    const uploadResult = await this.post("", {}, { body: formData, uploadFile: true })
      .then(res => res.json())
      .then(res => res.result);

    delete this.uploadingFiles[fileUploadId];

    return uploadResult;
  }


  // eslint-disable-next-line no-unused-vars
  async replace(idFile, body, onProgress = null) {
    // eslint-disable-next-line no-empty-function
    onProgress = onProgress ?? (() => {});

    let formData = new FormData();
    for (const prop in body) {
      formData.append(prop, body[prop]);
    }

    const fileUploadId = Utility.uid("fu");
    this.uploadingFiles[fileUploadId] = true;

    const uploadResult = await this.post(`replace/${idFile}`, {}, { body: formData, uploadFile: true })
      .then(res => res.json())
      .then(res => res.result);

    delete this.uploadingFiles[fileUploadId];

    return uploadResult;
  }

  async compress(idFile){
    return await this.put(`compress/${idFile}`)
    .then(res => res.json())
    .then((res) => res.result);
  }

  async update(id, body) {
    return await this.put(id, body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async rename(file, newName) {
      return await this.update(file.id, { ...file, fileName: newName, downloadName: newName });
  }

  async move(file, folder) {
    return await this.put("move", { idDestinationFolder: folder.id, files: [ file.id ] })
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async restore(id) {
    return await this.put(`restore/${id}`)
      .then(res => res.json())
      .then((res) => res.result);
  }

  async remove(id) {
    return await this.delete(id)
      .then(res => res.json())
      .then((res) => res.result);
  }

  async multiRemove(ids) {
    return await this.delete(ids)
      .then(res => res.json())
      .then((res) => res.result);
  }
}

export const fileService = new FileService();