import enJson from "@/assets/translations/en.json";
import itJson from "@/assets/translations/it.json";

export const state = {
  currentLang: enJson,
  en: enJson,
  it: itJson
};

export const mutations = {
  SET_CURRENT_LANG(state, lang) {
    switch (lang) {
      case "en":
        state.currentLang = state.en;
        break;

      case "it":
        state.currentLang = state.it;
        break;

      default:
        state.currentLang = state.en;
        break;
    }
  }
};
