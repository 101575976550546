export const state = {
  currentUser: null
};

export const mutations = {
  SET_CURRENT_USER(state, currentUser) {
    state.currentUser = currentUser;
  },
  RESET_CURRENT_USER(state) {
    state.currentUser = null;
  }
};
