import { ApiHandler } from "@/lib/ApiHandler";

class FolderService extends ApiHandler {
  constructor() {
    super("/api/folders");
  }

  async list(filters = null) {
    return await this.get("", filters)
      .then(res => res.json())
      .then(res => res.result);
  }

  async read(id) {
    return await this.get(id)
      .then(res => res.json())
      .then((res) => res.result);
  }

  async create(body) {
    return await this.post("", body)
      .then(res => res.json())
      .then(res => res.result);
  }

  async rename(folder, newName) {
      return await this.update(folder.id, { ...folder, name: newName });
  }

  async move(folder, newParent) {
    return await this.put("move", { idDestinationFolder: newParent.id, folders: [ folder.id ] })
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async update(id, body) {
    return await this.put(id, body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async remove(id) {
    return await this.delete(id)
      .then(res => res.json())
      .then((res) => res.result);
  }

  async multiRemove(ids) {
    return await this.delete(ids)
      .then(res => res.json())
      .then((res) => res.result);
  }
}

export const folderService = new FolderService();