import _env from "@/plugins/env";

class ImageService {
    getImagePath(path) {
        if (path !== "/Links/") {
            return _env("VUE_APP_API_BASE_URL") + "/Files/" + path;
        }
        return null;
    }
}

export const imageService = new ImageService();