//import { localStorageService } from "@/services/localStorageService.js";
import { HTTPError } from "@/lib/errors";
import store from "../store";
import router from "../router";
import { StrUtility } from "@/lib/StrUtility";
import _env from "@/plugins/env";

export class ApiHandler {
  constructor(_endPoint) {
    this.endPoint = StrUtility.pathJoin(
      _env("VUE_APP_API_BASE_URL"),
      _endPoint.trimEnd("/"),
      "/"
    );
  }

  async _fetch(endpoint, options) {
    const resp = await fetch(endpoint, options);
    if (resp.ok || typeof window === "undefined") {
      return resp;
    }
    const code = resp.status;
    const statusText = resp.statusText;
    let body = null;
    try {
      body = await resp.json();
    } catch (err) {
      // no json body
      body = {};
    }
    if (code == 401) {
      localStorage.clear();
      // TODO: remove store and router from here? maybe a central error handler or an interceptor?
      store.commit("RESET_CURRENT_USER");

      router.push("/login");
    }
    throw new HTTPError(
      code,
      statusText,
      body?.Error?.Message ?? null,
      {
        ...(body?.Error ?? {}),
        endpoint: endpoint.replace(this.endPoint, "")
      }
    );
  }

  async get(suffix = "", data = {}, extraConfig = {}) {
    const queryString = queryStringBuilder(data);
    return await this._fetch(this.endPoint + suffix + queryString, getConfig({ credentials: "include", method: "GET", ...extraConfig }));
  }

  async post(suffix = "", body = {}, extraConfig = {}) {
    return await this._fetch(this.endPoint + suffix, getConfig({ credentials: "include", method: "POST", body: JSON.stringify(body), ...extraConfig }));
  }

  async put(suffix = "", body = {}, extraConfig = {}) {
    return await this._fetch(this.endPoint + suffix, getConfig({ credentials: "include", method: "PUT", body: JSON.stringify(body), ...extraConfig }));
  }

  async delete(suffix = "", data = {}, extraConfig = {}) {
    const queryString = queryStringBuilder(data);
    return await this._fetch(this.endPoint + suffix + queryString, getConfig({ credentials: "include", method: "DELETE", ...extraConfig }));
  }

  async patch(suffix = "", body = {}, extraConfig = {}) {
    return await this._fetch(this.endPoint + suffix, getConfig({ credentials: "include", method: "PATCH", body: JSON.stringify(body), ...extraConfig }));
  }
}

function getHeaders(opts) {
  const user = JSON.parse(localStorage.getItem("currentUser")) ?? null;
  let headers = {};
  if (user) {
    let authToken = user.jwtToken;
    headers = {
      Authorization: `Bearer ${authToken}`
    };
  }

  if (!opts?.uploadFile) {
    headers["Content-type"] = "application/json";
  }

  return headers;
}


function getConfig(opts) {
  return {
    mode: "cors",
    headers: getHeaders(opts),
    redirect: "follow",
    ...opts
  };
}

function queryStringBuilder(params) {
  if (!params) {
    return "";
  }

  const queryStringFragment = Object.keys(params).map(key => {
    if (Array.isArray(params[key])) {
      return params[key].map(p => {
        return key + "[]=" + p;
      }).join("&");
    }
    return key + "=" + params[key];
  }).join("&");
  return queryStringFragment ? "?" + queryStringFragment : "";
}
