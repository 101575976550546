import store from "../store";
import {
  ApiHandler
} from "@/lib/ApiHandler";

class AuthService extends ApiHandler {
  constructor() {
    super("/api/auth");
  }


  checkLoggedUser() {
    if (store.state.authStore.currentUser != null) {
      return true;
    }
    const currentUserStorage = localStorage.getItem("currentUser");
    if (currentUserStorage) {
      store.commit("SET_CURRENT_USER", JSON.parse(currentUserStorage));
      return true;
    }
    return false;
  }

  async validateResetToken(_body) {
    return await this.post(
        "validate-reset-token",
        _body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async forgotPassword(_body) {
    return await this.post(
        "forgot-password",
        _body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async resetPassword(_body) {
    return await this.post(
        "reset-password",
        _body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async logout(body) {
    return await this.post("revoke-token", body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async login(body) {
    return await this.post("authenticate", body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }
}

export const authService = new AuthService();