export const state = {
  toast: {
    text: "",
    type: "",
    visible: false
  }
};

export const mutations = {
  SET_TOAST(state, payload) {
    state.toast.text = payload.text;
    state.toast.type = payload.type;
  },
  RESET_TOAST(state) {
    state.toast = {
      text: "",
      type: "",
      visible: false
    };
  },
  SHOW_TOAST(state) {
    state.toast.visible = true;
  },
  HIDE_TOAST(state) {
    state.toast.visible = false;
  }
};
