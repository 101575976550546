class GTIService {

  async getUsers (gTIDomainName, tokenAuthModuloCallMeGTI, tokenPresenceGTI) {
    try {
      const response = await fetch(`https://${gTIDomainName}/callme/presence/${tokenPresenceGTI}/roster`, {
        method: "GET",
        headers: {
          "X-Auth-Callme": tokenAuthModuloCallMeGTI
        }
      });

      const data = await response.json();
      return data.data.extensions;
    } catch (err) {
      return [];
    }
  }

}

export const gtiService = new GTIService();
