<template>
  <div
    v-if="icon != null && !isFlag"
    class="svg-icon"
    :class="{ flexed: isFlex }"
    v-html="require(`!html-loader!@/assets/img/icons/${icon}.svg`)"
  ></div>
  <div
    v-else-if="icon != null && isFlag"
    class="svg-icon"
    :class="{ flexed: isFlex }"
    v-html="require(`!html-loader!@/assets/img/flags/${icon}.svg`)"
  ></div>
</template>

<script>
export default {
  name: "Svg-icon",
  props: {
    icon: String,
    isFlag: Boolean,
    isFlex: Boolean
  }
};
</script>

<style lang="scss" scoped>
.svg-icon {
  &.flexed {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
