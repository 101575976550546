<template>
  <div
    class="container"
    :class="[
      fullWidth ? 'full' : null,
      flexClass,
      flexJustify ? 'full' : null,
      flexJustifyClass,
      flexWrapClass
    ]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    fullWidth: Boolean,
    flexWidth: String,
    flexJustify: String, // center / flex-start / flex-end / space-between / space-around
    flexWrap: Boolean
  },
  data() {
    return {
      flexClass: this.flexWidth ? "flexed flex-" + this.flexWidth : null,
      flexJustifyClass: this.flexJustify ? "justify-" + this.flexJustify : null,
      flexWrapClass: this.flexWrap ? "flex-wrap" : null
    };
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  max-width: 1600px;
  padding: 0 30px;

  &.full {
    max-width: none;
    padding: 0;
  }

  &.flexed {
    &.justify-center {
      justify-content: center;
    }
    &.justify-flex-start {
      justify-content: flex-start;
    }
    &.justify-flex-end {
      justify-content: flex-end;
    }
    &.justify-space-between {
      justify-content: space-between;
    }
    &.justify-space-around {
      justify-content: space-around;
    }

    &.flex-wrap {
      flex-wrap: wrap;
    }
  }

  &.flex-all {
    display: flex;
  }
  &.flex-xs {
    @media (min-width: $xs) {
      display: flex;
    }
  }
  &.flex-sm {
    @media (min-width: $sm) {
      display: flex;
    }
  }
  &.flex-md {
    @media (min-width: $md) {
      display: flex;
    }
  }
  &.flex-lg {
    @media (min-width: $lg) {
      display: flex;
    }
  }
  &.flex-xl {
    @media (min-width: $xl) {
      display: flex;
    }
  }
}
</style>
