import { ApiHandler } from "@/lib/ApiHandler";

class GalleryService extends ApiHandler {
  constructor () {
    super("/api/galleries");
  }

  async list () {
    return await this.get("")
      .then(res => res.json())
      .then(res => res.result);
  }

  async read (id) {
    return await this.get(id)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async create (body) {
    return await this.post("", body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async update (id, body) {
    return await this.put(id, body)
      .then(res => res.json())
      .then((res) => {
        return res.result;
      });
  }

  async setEnabled (idGallery, status) {
    return await this.patch(`${idGallery}/${status}`)
      .then(res => res.json())
      .then(res => res);
  }

  async readGalleryImage (idGallery, idGalleryImage) {
    return await this.get(`${idGallery}/galleryimages/${idGalleryImage}`)
      .then(res => res.json())
      .then(res => res.result);
  }

  async createGalleryImage (idGallery, body) {
    return await this.post(`${idGallery}/galleryimages`, body)
      .then(res => res.json())
      .then(res => res.result);
  }

  async updateGalleryImage (idGallery, idGalleryImage, body) {
    return await this.put(`${idGallery}/galleryimages/${idGalleryImage}`, body)
      .then(res => res.json())
      .then(res => res.result);
  }

  async deleteGalleryImage (idGallery, idGalleryImage) {
    return await this.delete(`${idGallery}/galleryimages/${idGalleryImage}`)
      .then(res => res.json())
      .then(res => res.result);
  }

  async saveGalleryImagesOrder (idGallery, orderedGalleries) {
    return await this.put(`${idGallery}/updateOrder`, orderedGalleries)
      .then(res => res.json())
      .then(res => res);
  }
}

export const galleryService = new GalleryService();
