import { extend } from "vee-validate";
import moment from "moment";
import * as rules from "vee-validate/dist/rules";

/**
 * Default rules:
 *    https://vee-validate.logaretm.com/v3/guide/rules.html#rules
 */
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

function formatAsMomentDate(val) {
  let localeFormat = moment.localeData(window.navigator.language).longDateFormat("L");
  let momentObj = moment(val, localeFormat);
  return momentObj.toDate();
}

function formatAsMomentDateTime(val) {
  let date = val.split(",");
  let localeFormatDate = moment.localeData(window.navigator.language).longDateFormat("L");
  let localeFormatTime = moment.localeData(window.navigator.language).longDateFormat("LTS");
  let momentDateObj = moment(val, localeFormatDate);
  let momentTimeObj = moment(date[1], localeFormatTime);

  return mergeDateTime(momentTimeObj.toDate(), momentDateObj.toDate());
}

function mergeDateTime(start_time, start_date){
  const time = moment(start_time).format("HH:mm:ss");
  const d = moment(start_date).format("YYYY-MM-DD");
  let isoTimestamp = `${d}T${time}`;
  const final_start_date = moment(isoTimestamp).format();
  return final_start_date;
}
// Miscellaneous rules

extend("gt", {
  validate(value, { target }) {
    return value > target;
  },
  params: ["target"],
  message: "The {_field_} field must be greater than {target}"
});

extend("lt", {
  validate(value, { target }) {
    return value < target;
  },
  params: ["target"],
  message: "The {_field_} field must be lesser than {target}"
});

extend("gte", {
  validate(value, { target }) {
    return value >= target;
  },
  params: ["target"],
  message: "The {_field_} field must be greater or equal than {target}"
});

extend("lte", {
  validate(value, { target }) {
    return value <= target;
  },
  params: ["target"],
  message: "The {_field_} field must be lesser or equal than {target}"
});

// Date rules

extend("minDate", {
  validate(value, { minDate }) {
    return formatAsMomentDate(value) >= formatAsMomentDate(minDate);
  },
  params: ["minDate"],
  message: "The {_field_} field must be greater or equal than {minDate}"
});

extend("gtDate", {
  validate(value, { minDate }) {
    return formatAsMomentDateTime(value) > formatAsMomentDateTime(minDate);
  },
  params: ["minDate"],
  message: "The {_field_} field must be greater than {minDate}"
});

extend("maxDate", {
  validate(value, { maxDate }) {
    return formatAsMomentDate(value) <= formatAsMomentDate(maxDate);
  },
  params: ["maxDate"],
  message: "The {_field_} field must be lesser or equal than {maxDate}"
});

extend("minDateNow", {
  validate(value) {
    return formatAsMomentDate(value) >= Date.now();
  },
  message: "The {_field_} field must be greater or equal than " + new Date().toISOString().slice(0, 16).replace("T", " ")
});

extend("maxDateNow", {
  validate(value) {
    return formatAsMomentDate(value) <= Date.now();
  },
  message: "The {_field_} field must be lesser or equal than " + new Date().toISOString().slice(0, 16).replace("T", " ")
});

// Text rules

extend("forbiddenCharacters", {
  validate(value) {
    const re = /^[a-z\d\-_]+$/i;
    return re.test(value);
  },
  message: "The {_field_} contains forbidden characters"
});

extend("emailFormat", {
  validate(value) {
    const re = /^(([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,24}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/;
    return re.test(String(value).toLowerCase());
  },
  message: "The {_field_} must be a valid email address"
});

extend("youtubeEmbedLink", {
  validate(value) {
    const re = /^https:\/\/www.youtube.com\/embed\/.*$/;
    return re.test(String(value).toLowerCase());
  },
  message: "The {_field_} must be a valid embedded YouTube link"
});

extend("youtubeLink", {
  validate(value) {
    const re = /^https:\/\/www.youtube.com\/watch\?v=.*$/;
    return re.test(String(value).toLowerCase());
  },
  message: "The {_field_} must be a valid YouTube link"
});

extend("containsAutoplay", {
  validate(value) {
    const re = /.*\?autoplay=1$/;
    return re.test(String(value).toLowerCase());
  },
  message: "The {_field_} must contains '?autoplay=1' at the end of the link"
});

// Array rules

extend("isArray", {
  validate(value) {
    return Array.isArray(value);
  },
  message: "The {_field_} must be a valid array"
});

extend("minElements", {
  validate(value, { minElements }) {
    if (!Array.isArray(value)) {
      return false;
    }
    return value.length >= minElements;
  },
  params: ["minElements"],
  message: "The {_field_} must contain at least {minElements} elements"
});

extend("maxElements", {
  validate(value, { maxElements }) {
    if (!Array.isArray(value)) {
      return false;
    }
    return value.length >= maxElements;
  },
  params: ["maxElements"],
  message: "The {_field_} must contain at most {maxElements} elements"
});